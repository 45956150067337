import React, { useEffect, useState, useCallback } from "react";
import { FieldProps, getFieldObject, getItemComponent } from "../Forms/Fields/FieldBuilder";
import {
    AutoComplete,
    AutoCompleteContainer,
    FormContainer,
    SwitchBase,
    TitleContainer
} from "./styles";

import AutomationField from "../../dialogs/AutomationField";
import { BsLightningCharge, BsLightningChargeFill } from "react-icons/bs";
import Description from "../../components/Description";
import Title from "../../components/Title";
import getAccessControl from "../../middlewares/AccessControl";
import { Card } from "../../interfaces/Card";

interface FieldContainerProps {
    field: FieldProps;
    onInitValue?: boolean;
    hideInfoField?: boolean;
    hideAutoComplete?: boolean;
    flow_id?: number;
    register_id?: number;
    typeUser: string;
    flow_parent_id?: number; //If there is a parent sync
    register_parent_id?: number; //If there is a parent sync
    card_current_id?: number; //If there is a parent sync
    card_current?: Card;
    isNewAnswer?: boolean;
    isPublicForm?: boolean;
    public_hash?: string;
    activeHiddenFields?: boolean; //Active the show_on_form field
    updateFields?(): Promise<void>;
    onForceBlur?(): Promise<void>;
}

const FieldContainer: React.FC<FieldContainerProps> = ({ field, onInitValue, hideInfoField, hideAutoComplete, flow_id, register_id, typeUser, flow_parent_id, register_parent_id, card_current_id, card_current, isNewAnswer, isPublicForm, public_hash, activeHiddenFields, updateFields, onForceBlur }) => {

    const [switchState, setSwitchState] = useState(false);
    const [openAutoComplete, setOpenAutoComplete] = useState(false);

    const Field = getItemComponent(field.type);

    const onAutoComplete = (field.ac_type !== undefined && field.ac_type !== null) || (field.map_ac !== undefined && field.map_ac !== null && field.map_ac.length > 0) ? true : false;
    const onConditionals = (field.conditionals !== undefined && field.conditionals !== null && field.conditionals.length > 0) ? true : false;    

    if (!field.turn_on_field || onInitValue) {
        field.on_field = true;
    }

    const hideField = activeHiddenFields && ((field.show_on_form !== undefined && field.show_on_form !== null && field.show_on_form === "S") || (field.type === "ID_FIELD")) ? true : false;

    const switchHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        field.on_field = event.target.checked;
        setSwitchState(event.target.checked);
    };

    const handleCloseAutoComplete = useCallback((open: boolean) => {

        setOpenAutoComplete(open);

        if (updateFields !== undefined) {
            updateFields();
        }

    }, [updateFields]);

    useEffect(() => {

        //Para quando inicia um novo Field
        if (field.turn_on_field) {
            setSwitchState(false);
            field.on_field = false;
        } else {
            setSwitchState(true);
            field.on_field = true;
        }

        //Para quando já inicia com um valorr
        if (onInitValue) {
            setSwitchState(true);
            field.on_field = true;
        }

    }, [field, field.type, onInitValue]);

    return (
        <FormContainer key={field.type + field.name} idField={String(field.id_field)} hideField={hideField}>
            {/* Title */}
            {field.type !== "TITLE_FIELD" && field.type !== "DESCRIPTION_FIELD" && field.type !== "DIVIDER_FIELD" && field.type !== "DYNAMIC_TEXT_FIELD" ? (
                <TitleContainer>
                    <Title title={field.title} required={field.required} help_text={field.help_text} />
                    {/* Auto Complete */}
                    {!hideAutoComplete && field.id_field !== undefined && field.id_field > 0 && (flow_id !== undefined || register_id !== undefined) ?
                        <AutoCompleteContainer
                            id={"auto-complete" + field.id_field}
                            isFilled={onAutoComplete || onConditionals}
                            onClick={() => {
                                setOpenAutoComplete(true);
                            }}
                            title={"Automações de Campos"}
                        >
                            <AutoComplete>
                                {onAutoComplete || onConditionals ?
                                    <BsLightningChargeFill color="#f23b5d" /> :
                                    getAccessControl(46, typeUser) ?
                                        <BsLightningCharge /> :
                                        <></>
                                }
                            </AutoComplete>
                            {openAutoComplete && getAccessControl(46, typeUser) && (
                                <AutomationField
                                    key={field.id_field}
                                    open={openAutoComplete}
                                    onClose={handleCloseAutoComplete}
                                    flow_parent_id={flow_parent_id}
                                    register_parent_id={register_parent_id}
                                    field={field}
                                    flow_id={flow_id}
                                    register_id={register_id}
                                    isNewAnswer={isNewAnswer}
                                    hideAutoComplete={!getFieldObject(field.type).onAutoComplete}
                                />
                            )}
                        </AutoCompleteContainer> :
                        <></>
                    }
                </TitleContainer>
            ) : <></>}

            {/* Switch to open the field */}
            {field.turn_on_field ? (
                <SwitchBase
                    checked={switchState}
                    name={"SH-" + field.name}
                    onChange={switchHandleChange}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                />
            ) : (
                <></>
            )}

            {/* Description */}
            <Description>{field.description}</Description>

            {/* Field */}
            {!!field.on_field ? (
                <Field
                    id_field={field.id_field}
                    name={field.name}
                    type={field.type}
                    variation={field.variation}
                    title={field.title}
                    description={field.description}
                    index={field.index}
                    placeholder={field.placeholder}
                    help_text={field.help_text}                    
                    required={field.required}
                    validation_type={field.validation_type}
                    validations={field.validations}
                    turn_on_field={field.turn_on_field}
                    on_field={field.on_field}
                    options={field.options}
                    max_length={field.max_length}
                    company_id={field.company_id}
                    form_id={field.form_id}
                    register_id={field.register_id}
                    flow_id={field.flow_id}
                    filter_schema={field.filter_schema}
                    user_id_creator={field.user_id_creator}
                    unique={field.unique}
                    card_title={field.card_title}
                    card_highlight={field.card_highlight}
                    card_aggregate={field.card_aggregate}
                    inline_picker={field.inline_picker}
                    on_edit={field.on_edit}
                    formula={field.formula}
                    updated={field.updated}
                    show_on_form={field.show_on_form}
                    ac_type={field.ac_type}
                    ac_parent_field_id={field.ac_parent_field_id}
                    ac_child_field_id={field.ac_child_field_id}
                    ac_form_answer_id={field.ac_form_answer_id}
                    currentEdit={field.currentEdit}
                    register={field.register}
                    flow={field.flow}
                    formFields={field.formFields}
                    conditionals={field.conditionals}
                    previewField={field.previewField}
                    auto_complete={field.auto_complete}
                    flow_base_id={flow_id}
                    register_base_id={register_id}
                    card_current_id={card_current_id}
                    card_current={card_current}
                    field_children={field.field_children}
                    map_ac={field.map_ac}
                    isPublicForm={isPublicForm}
                    public_hash={public_hash}
                    updatedByConditional={field.updatedByConditional}
                    propsBase={field.propsBase}
                    isFromAutomation={field.isFromAutomation}
                    onForceBlur={onForceBlur}
                />
            ) : (
                <></>
            )}
        </FormContainer>
    );
};

export default FieldContainer;