import React from "react";

import OverlayFreemium from "../OverlayFreemium";
import ChoosePlan from "../../../dialogs/Billing/ChoosePlan";
import { useAuth } from "../../../hooks/auth";

interface FreemiumFeatureProps {
    children: React.ReactNode,
    isPaidFeature: boolean,
    title: string,
    description: string,
    buttonText: string,
    planRequired: string,
    maxWidth?: number,
    handleUpgrade?: () => void
}

const FreemiumFeature: React.FC<FreemiumFeatureProps> = ({ isPaidFeature, children, title, description, buttonText, planRequired, maxWidth, handleUpgrade }) => {

    const { renewUser } = useAuth();
    const [openBilling, setOpenBilling] = React.useState(false);

    return (
        <div style={{ position: 'relative' }}>
            {isPaidFeature && (
                <OverlayFreemium
                    title={title}
                    description={description}
                    buttonText={buttonText}
                    planRequired={planRequired}
                    maxWidth={maxWidth}
                    onUpgrade={handleUpgrade !== undefined ? handleUpgrade : () => setOpenBilling(true)}
                />)
            }
            {children}

            <ChoosePlan
                open={openBilling}
                onClose={() => {
                    renewUser();
                    setOpenBilling(false);
                }}
                isEndTrial={false}
            />
        </div>
    );

}

export default FreemiumFeature;