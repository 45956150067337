import React, { useState } from "react";
import { BsGearFill } from "react-icons/bs";
import LayoutContainer from '../../components/LayoutContainer';
import ListContainer from '../../components/ListContainer';
import AppBarHeader, { NavigationBarItemType } from "../../components/AppBarHeader";
import {
    Container,
    Content
} from "./style";
import { useAuth } from "../../hooks/auth";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import CompanyProfile from "./CompanyProfile";

interface CompanyConfigsProps {

}

const CompanyConfigs: React.FC<CompanyConfigsProps> = () => {

    const { user } = useAuth();
    const [navBarSelected, setNavBarSelected] = useState(1);

    const navBarItems: NavigationBarItemType[] = [
        {
            index: 1,
            description: "Geral",
            icon: BsGearFill,
            active: navBarSelected === 1 ? true : false,
            onClick: () => setNavBarSelected(1)
        }
    ]

    return (
        <LayoutContainer>
            <title>{"Painel do Administrador"} | Cange</title>
            <ListContainer>

                {/* Header Page */}
                <AppBarHeader
                    icon={HiOutlineOfficeBuilding}
                    title={"Painel do Administrador"}
                    navBarItems={navBarItems}
                    isRoundedCorners={true}
                    typeUser={user.type}
                >

                </AppBarHeader>

                {/* Inputs Form */}
                <Container>
                    <Content container>

                        {navBarSelected === 1 ?
                            <CompanyProfile /> :
                            <></>
                        }

                    </Content>
                </Container>

            </ListContainer>
        </LayoutContainer>

    );

}

export default CompanyConfigs;