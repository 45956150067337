import { FieldProps, Fields } from "../../../../components/Forms/Fields/FieldBuilder";

export interface ConfigFieldAutomation {
    fields: string[];
}

/* From the field.config_fields */
export default function getConfigFields(configField: ConfigFieldAutomation, flow_id?: number): FieldProps[] {

    let newFields: FieldProps[] = [];

    if (configField.fields === undefined || configField.fields === null || configField.fields.length === 0) return [];

    for (let index = 0; index < configField.fields.length; index++) {
        const field = configField.fields[index];

        if (field === "trigger_flow_id" && flow_id === undefined) {
            newFields.push(TRIGGER_FLOW_ID);
        } else if (field === "trigger_flow_step_id") {
            newFields.push(TRIGGER_FLOW_STEP_ID);
        } else if (field === "action_flow_step_id") {
            newFields.push(ACTION_FLOW_STEP_ID);
        } else if (field === "trigger_field_id") {
            newFields.push(TRIGGER_FIELD_ID);
        } else if (field === "trigger_tag_id") {
            newFields.push(TRIGGER_TAG_ID);
        } else if (field === "action_tag_id") {
            newFields.push(ACTION_TAG_ID);
        } else if (field === "trigger_time_type") {
            newFields.push(TRIGGER_TIME_TYPE);
        } else if (field === "trigger_time_value") {
            newFields.push(TRIGGER_TIME_TYPE);
        } else if (field === "action_email_to") {
            newFields.push(ACTION_EMAIL_TO);
        } else if (field === "action_email_subject") {
            newFields.push(ACTION_EMAIL_SUBJECT);
        } else if (field === "action_email_message") {
            newFields.push(ACTION_EMAIL_MESSAGE);
        } else if (field === "action_email_from_name") {
            newFields.push(ACTION_EMAIL_FROM_NAME);
        } else if (field === "action_email_from_email") {
            newFields.push(ACTION_EMAIL_FROM_EMAIL);
        } else if (field === "action_email_cc") {
            newFields.push(ACTION_EMAIL_CC);
        } else if (field === "action_email_cco") {
            newFields.push(ACTION_EMAIL_CCO);
        } else if (field === "action_ai_field_id") {
            newFields.push(ACTION_AI_FIELD_ID);
        } else if (field === "action_ai_prompt") {
            newFields.push(ACTION_AI_PROMPT);
        } else if (field === "action_ai_system") {
            newFields.push(ACTION_AI_SYSTEM);
        } else if (field === "trigger_time_hour") {
            newFields.push(TRIGGER_TIME_HOUR);
        } else if (field === "trigger_time_weekend") {
            newFields.push(TRIGGER_TIME_WEEKEND);
        } else if (field === "trigger_time_weekday") {
            newFields.push(TRIGGER_TIME_WEEKDAY);
        } else if (field === "trigger_time_monthday") {
            newFields.push(TRIGGER_TIME_MONTHDAY);
        } else if (field === "trigger_time_month") {
            newFields.push(TRIGGER_TIME_MONTH);
        } else if (field === "action_new_card") {
            newFields.push(ACTION_NEW_CARD);
        } else if (field === "action_flow_id") {
            newFields.push(ACTION_FLOW_ID);
        } else if (field === "trigger_user_id") {
            newFields.push(TRIGGER_USER_ID);
        } else if (field === "action_user_id") {
            newFields.push(ACTION_USER_ID);
        } else if (field === "action_has_log") {
            newFields.push(ACTION_HAS_LOG);
        } else if (field === "trigger_flow_target_id") {
            newFields.push(TRIGGER_FLOW_TARGET);
        } else if (field === "trigger_flow_step_target_id") {
            newFields.push(TRIGGER_FLOW_STEP_TARGET_ID);
        } else if (field === "action_email_attachment") {
            newFields.push(ACTION_EMAIL_ATTACHMENT);
        } else if (field === "action_flow_field_id") {
            newFields.push(ACTION_FLOW_FIELD_ID);
        } else if (field === "action_new_card_child_warning") {
            newFields.push(ACTION_NEW_CARD_CHILD_WARNING);
        } else if (field === "action_web_url") {
            newFields.push(ACTION_WEB_URL);
        } else if (field === "action_web_http_method") {
            newFields.push(ACTION_WEB_HTTP_METHOD);
        } else if (field === "action_web_headers") {
            newFields.push(ACTION_WEB_HEADERS);
        } else if (field === "action_web_body") {
            newFields.push(ACTION_WEB_BODY);
        } else if (field === "trigger_days_after_due") {
            newFields.push(TRIGGER_DAYS_AFTER_DUE);
        } else if (field === "trigger_days_before_due") {
            newFields.push(TRIGGER_DAYS_BEFORE_DUE);
        } 
    }

    return newFields;

}

const ACTION_HAS_LOG: FieldProps = {
    name: "action_has_log",
    type: Fields.SWITCH_FIELD,
    index: 1,
    title: "Adicionar log de alteração?",
    description: "Define se a automação deve gerar log de alteração no cartão",
    help_text: "Se ativado, a automação irá gerar um log de alteração no cartão",
}

const TRIGGER_FLOW_ID: FieldProps = {
    name: "trigger_flow_id",
    type: Fields.COMBO_BOX_FIELD,
    required: true,
    index: 0,
    title: "Fluxo",
    description: "Selecione o fluxo que você deseja criar a automação",
    placeholder: "Selecione um fluxo...",
    help_text: "Escolha um fluxo que será monitorado para a execução da automação",
    turn_on_field: false,
    options: [
    ],
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatória a seleção de um fluxo!"]
        }
    ]
}

const TRIGGER_FLOW_STEP_ID: FieldProps = {
    name: "trigger_flow_step_id",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "4",
    required: true,
    index: 1,
    title: "Etapa",
    description: "Escolha a etapa que você deseja criar a automação",
    placeholder: "Selecione uma etapa...",
    help_text: "Selecione a etapa que será monitorado para a execução da automação",
    turn_on_field: false,
    options: [
    ],
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatória a seleção de uma etapa!"]
        }
    ]
}

const TRIGGER_FLOW_STEP_TARGET_ID: FieldProps = {
    name: "trigger_flow_step_target_id",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "4",
    required: true,
    index: 1,
    title: "Etapa do fluxo filho",
    description: "Escolha a etapa que você deseja criar a automação",
    placeholder: "Selecione uma etapa...",
    help_text: "Selecione a etapa que será monitorado para a execução da automação",
    turn_on_field: false,
    options: [
    ],
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatória a seleção de uma etapa!"]
        }
    ]
}

const ACTION_FLOW_STEP_ID: FieldProps = {
    name: "action_flow_step_id",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "4",
    required: true,
    index: 1,
    title: "Etapa",
    description: "Escolha a etapa que você deseja criar a automação",
    placeholder: "Selecione uma etapa...",
    help_text: "Selecione a etapa que será monitorado para a execução da automação",
    turn_on_field: false,
    options: [
    ],
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatória a seleção de uma etapa!"]
        }
    ]
}

const TRIGGER_FIELD_ID: FieldProps = {
    name: "trigger_field_id",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "4",
    required: true,
    index: 2,
    title: "Campo",
    description: "Escolha o campo que você deseja criar a automação",
    placeholder: "Selecione um campo...",
    help_text: "Selecione o campo que será monitorado para a execução da automação",
    turn_on_field: false,
    options: [
    ],
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatória a seleção de um campo!"]
        }
    ]
}

const TRIGGER_USER_ID: FieldProps = {
    name: "trigger_user_id",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "4",
    required: false,
    index: 2,
    title: "Responsável",
    description: "Escolha o responsável que você deseja criar a automação",
    placeholder: "Selecione um responsável...",
    help_text: "Selecione o responsável que será monitorado para a execução da automação",
    turn_on_field: false,
    options: [
    ],
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatória a seleção de um campo!"]
        }
    ]
}

const ACTION_USER_ID: FieldProps = {
    name: "action_user_id",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "4",
    required: false,
    index: 2,
    title: "Responsável",
    description: "Escolha o responsável que você deseja inserir no cartão",
    placeholder: "Selecione um responsável...",
    help_text: "Selecione o responsável que será inserido no cartão pela automação",
    turn_on_field: false,
    options: [
    ],
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatória a seleção de um campo!"]
        }
    ]
}

const TRIGGER_TAG_ID: FieldProps = {
    name: "trigger_tag_id",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "4",
    required: true,
    index: 3,
    title: "Etiqueta",
    description: "Escolha a etiqueta que você deseja criar a automação",
    placeholder: "Selecione uma etiqueta...",
    help_text: "Selecione a etiqueta que será monitorada para a execução da automação",
    turn_on_field: false,
    options: [
    ],
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatória a seleção de uma etiqueta!"]
        }
    ]
}

const ACTION_TAG_ID: FieldProps = {
    name: "action_tag_id",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "4",
    required: true,
    index: 3,
    title: "Etiqueta",
    description: "Escolha a etiqueta que você adicionar no cartão",
    placeholder: "Selecione uma etiqueta...",
    help_text: "Selecione a etiqueta que será inserida no cartão",
    turn_on_field: false,
    options: [
    ],
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatória a seleção de uma etiqueta!"]
        }
    ]
}

const TRIGGER_TIME_TYPE: FieldProps = {
    name: "trigger_time_type",
    type: Fields.COMBO_BOX_FIELD,
    required: true,
    index: 4,
    title: "Frequência",
    description: "Escolha qual é a frequência que você deseja executar a automação",
    placeholder: "Selecione uma frequência...",
    help_text: "Escolha a frequência que será monitorada para a execução da automação",
    turn_on_field: false,
    options: [
    ],
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatória a seleção de uma frequência!"]
        }
    ]
}

const ACTION_EMAIL_TO: FieldProps = {
    name: "action_email_to",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "2",
    max_length: 2,
    required: true,
    index: 0,
    title: "Para",
    description: "Digite o endereço de email do destinatário do email",
    placeholder: "Digite o email...",
    help_text: "Informe o email do destinatário do email",
    turn_on_field: false,
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatória a seleção de um email!"]
        }
    ]
}

const ACTION_EMAIL_SUBJECT: FieldProps = {
    name: "action_email_subject",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "1",
    required: true,
    index: 1,
    title: "Assunto",
    description: "Digite o assunto do email",
    placeholder: "Digite o assunto...",
    help_text: "Informe o assunto do email",
    turn_on_field: false,
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatório ter um assunto!"]
        }
    ]
}

const ACTION_EMAIL_MESSAGE: FieldProps = {
    name: "action_email_message",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "5",
    required: true,
    index: 2,
    title: "Mensagem",
    description: "Digite a mensagem do email",
    placeholder: "Digite uma mensagem...",
    help_text: "Informe uma mensagem do email para ir no corpo do e-mail",
    turn_on_field: false,
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatório ter uma mensagem!"]
        }
    ]
}

const ACTION_EMAIL_FROM_NAME: FieldProps = {
    name: "action_email_from_name",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "1",
    required: false,
    index: 3,
    title: "Nome",
    description: "Digite o nome do remetente do email",
    placeholder: "Digite o nome...",
    help_text: "Informe o nome do remetente do email",
    turn_on_field: true
}

const ACTION_EMAIL_FROM_EMAIL: FieldProps = {
    name: "action_email_from_email",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "2",
    required: false,
    index: 4,
    title: "Email",
    description: "Digite o endereço de email do remetente do email",
    placeholder: "Digite o email...",
    help_text: "Informe o email do remetente do email. Este e-mail quando preenchido será replicado para o campo de reply_to também para que o destinatário possa responder o email ao remetente.",
    turn_on_field: true
}

const ACTION_EMAIL_CC: FieldProps = {
    name: "action_email_cc",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "2",
    max_length: 2,
    required: false,
    index: 5,
    title: "CC",
    description: "Digite o endereço de email dos destinatários CC",
    placeholder: "Digite o email...",
    help_text: "Informe o email do destinatário do email para enviar cópia",
    turn_on_field: true
}

const ACTION_EMAIL_CCO: FieldProps = {
    name: "action_email_cco",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "2",
    max_length: 2,
    required: false,
    index: 6,
    title: "CCo",
    description: "Digite o endereço de email dos destinatários CCO",
    placeholder: "Digite o email...",
    help_text: "Informe o email do destinatário do email para enviar cópia oculta",
    turn_on_field: true
}

const ACTION_AI_FIELD_ID: FieldProps = {
    name: "action_ai_field_id",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "4",
    required: true,
    index: 0,
    title: "Campo",
    description: "Escolha o campo que você deseja preencher",
    placeholder: "Selecione um campo...",
    help_text: "Selecione o campo que será preenchido na execução da automação",
    turn_on_field: false,
    options: [
    ],
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatória a seleção de um campo!"]
        }
    ]
}

const ACTION_AI_PROMPT: FieldProps = {
    name: "action_ai_prompt",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "3",
    required: true,
    index: 2,
    title: "Prompt para a IA",
    description: "Digite a instrução para a IA gerar o conteúdo",
    placeholder: "Faça determinada tarefa...",
    help_text: "Informe a instrução para a IA gerar o conteúdo para preenchimento do campo",
    turn_on_field: false,
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatório ter um prompt para a IA!"]
        }
    ]
}

const ACTION_AI_SYSTEM: FieldProps = {
    name: "action_ai_system",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "3",
    required: true,
    index: 1,
    title: "Instrução para a IA",
    description: "Digite a instrução de como a IA deve se comportar",
    placeholder: "Você deve agir como...",
    help_text: "Informe a instrução de como a IA deve se comportar para gerar o conteúdo, passando as características e ações que deve realizar",
    turn_on_field: false,
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatório ter um prompt para a IA!"]
        }
    ]
}

const TRIGGER_TIME_HOUR: FieldProps = {
    name: "trigger_time_hour",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "4",
    required: true,
    index: 0,
    title: "Hora do dia",
    description: "Escolha a hora do dia que a automação será executada",
    placeholder: "Selecione uma hora...",
    help_text: "Escolha a hora do dia que será a execução da automação",
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatório escolher uma hora para criar este gatilho!"]
        }
    ]
}

const TRIGGER_TIME_WEEKEND: FieldProps = {
    name: "trigger_time_weekend",
    type: Fields.SWITCH_FIELD,
    index: 1,
    title: "Ignorar finais de semana",
    description: "Define se a automação deve ser executada nos finais de semana",
    help_text: "Se ativado, a automação não será executada nos finais de semana",
}

const TRIGGER_TIME_WEEKDAY: FieldProps = {
    name: "trigger_time_weekday",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "4",
    required: true,
    index: 0,
    title: "Dia da semana",
    description: "Escolha o dia da semana que a automação será executada",
    placeholder: "Selecione um dia da semana...",
    help_text: "Escolha o dia da semana que será a execução da automação",
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatório escolher um dia da semana para criar este gatilho!"]
        }
    ]
}

const TRIGGER_TIME_MONTHDAY: FieldProps = {
    name: "trigger_time_monthday",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "4",
    required: true,
    index: 0,
    title: "Dia do mês",
    description: "Escolha o dia do mês que a automação será executada",
    placeholder: "Selecione um dia do mês...",
    help_text: "Escolha o dia do mês que será a execução da automação",
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatório escolher um dia do mês para criar este gatilho!"]
        }
    ]
}

const TRIGGER_TIME_MONTH: FieldProps = {
    name: "trigger_time_month",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "4",
    required: true,
    index: 0,
    title: "Mês do Ano",
    description: "Escolha o mês do ano que a automação será executada",
    placeholder: "Selecione um mês do ano...",
    help_text: "Escolha o mês do ano que será a execução da automação",
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatório escolher um mês para criar este gatilho!"]
        }
    ]
}

const ACTION_NEW_CARD: FieldProps = {
    name: "action_new_card",
    type: Fields.COMBO_BOX_FLOW_FIELD,
    variation: "2",
    max_length: 1,
    required: true,
    index: 1,
    title: "Cartão",
    description: "Crie o cartão que será criado na execução da automação",
    placeholder: "Crie um novo cartão...",
    help_text: "Você pode deixar um cartão criado para ser utilizado na execução da automação",
    validation_type: "string",
    isFromAutomation: true,
    validations: [
        {
            type: "required",
            params: ["É obrigatório a seleção de um cartão!"]
        }
    ]
}

const ACTION_FLOW_ID: FieldProps = {
    name: "action_flow_id",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "4",
    required: true,
    index: 0,
    title: "Fluxo",
    description: "Escolha o fluxo que você deseja criar o cartão",
    placeholder: "Selecione um fluxo...",
    help_text: "Selecione o fluxo que será inserido o cartão",
    turn_on_field: false,
    options: [
    ],
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatória a seleção de um fluxo!"]
        }
    ]
}

const TRIGGER_FLOW_TARGET: FieldProps = {
    name: "trigger_flow_target_id",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "4",
    required: true,
    index: 0,
    title: "Fluxo filho",
    description: "Escolha o fluxo filho que será monitorado para a execução da automação",
    placeholder: "Selecione um fluxo...",
    help_text: "Este será o fluxo filho que será monitorado para a execução da automação",
    turn_on_field: false,
    options: [
    ],
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatória a seleção de um fluxo!"]
        }
    ]
}

const ACTION_EMAIL_ATTACHMENT: FieldProps = {
    name: "action_email_attachment",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "4",
    max_length: 2,
    required: false,
    index: 7,
    title: "Anexo",
    description: "Escolha o campo de anexo que você deseja adicionar no email",
    placeholder: "Selecione uma campo de anexo...",
    help_text: "Selecione o campo do tipo anexo que será inserido no email (Limite de 10MB por anexo)",
    turn_on_field: true
}

const ACTION_FLOW_FIELD_ID: FieldProps = {
    name: "action_flow_field_id",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "4",
    required: true,
    index: 1,
    title: "Campo",
    description: "Escolha o campo que você deseja criar o cartão filho",
    placeholder: "Selecione um campo...",
    help_text: "Selecione o campo que será inserido o cartão filho, este campo deve ser do tipo Meus Fluxos!",
    turn_on_field: false,
    options: [
    ],
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatória a seleção de um campo!"]
        }
    ]
}

const ACTION_NEW_CARD_CHILD_WARNING: FieldProps = {
    name: "action_new_card_child_warning",
    type: Fields.DESCRIPTION_FIELD,
    index: 0,
    title: "💡 Para que esta ação funcione da maneira desejada, é necessário os campos do formulário conectado estejam com o auto completar configurado. As informações do novo cartão será baseada nas regras de auto completar existentes.",
    description: ""
}

const ACTION_WEB_URL: FieldProps = {
    name: "action_web_url",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "7",
    required: true,
    index: 1,
    title: "URL",
    description: "Digite a URL da requisição HTTPS",
    placeholder: "Digite aqui...",
    help_text: "Informe a URL da requisição HTTPS que será feita",
    turn_on_field: false,
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatório ter uma URL!"]
        }
    ]
}

const ACTION_WEB_HTTP_METHOD: FieldProps = {
    name: "action_web_http_method",
    type: Fields.COMBO_BOX_FIELD,
    required: true,
    index: 0,
    title: "Método HTTP",
    description: "Escolha o método HTTP da requisição",
    placeholder: "Selecione um método...",
    help_text: "Escolha o método HTTP da requisição que será feita",
    turn_on_field: false,
    options: [
        { value: "GET", label: "GET" },
        { value: "POST", label: "POST" },
        { value: "PUT", label: "PUT" },
        { value: "DELETE", label: "DELETE" },
    ],
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatório ter um método HTTP!"]
        }
    ]
}

const TRIGGER_DAYS_AFTER_DUE: FieldProps = {
    name: "trigger_days_after_due",
    type: Fields.COMBO_BOX_FIELD,
    required: true,
    index: 0,
    title: "Executar no dia",
    description: "Escolha o dia que a automação será executada",
    placeholder: "Selecione um dia...",
    help_text: "Escolha o dia que a automação será executada após o prazo de vencimento ou no dia do vencimento",
    turn_on_field: false,
    options: [
        { value: "0", label: "No dia do vencimento" },
        { value: "1", label: "1 Dia após o vencimento" },
        { value: "2", label: "2 Dias após o vencimento" },
        { value: "3", label: "3 Dias após o vencimento" },
        { value: "4", label: "4 Dias após o vencimento" },
        { value: "5", label: "5 Dias após o vencimento" },
        { value: "6", label: "6 Dias após o vencimento" },
        { value: "7", label: "7 Dias após o vencimento" },
        { value: "14", label: "14 Dias após o vencimento" },
        { value: "30", label: "30 Dias após o vencimento" },
    ],
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatória a escolha de um dia!"]
        }
    ]
}

const TRIGGER_DAYS_BEFORE_DUE: FieldProps = {
    name: "trigger_days_before_due",
    type: Fields.COMBO_BOX_FIELD,
    required: true,
    index: 0,
    title: "Executar no dia",
    description: "Escolha o dia que a automação será executada",
    placeholder: "Selecione um dia...",
    help_text: "Escolha o dia que a automação será executada antes do prazo de vencimento ou no dia do vencimento",
    turn_on_field: false,
    options: [
        { value: "0", label: "No dia do vencimento" },
        { value: "1", label: "1 Dia antes ao vencimento" },
        { value: "2", label: "2 Dias antes ao vencimento" },
        { value: "3", label: "3 Dias antes ao vencimento" },
        { value: "4", label: "4 Dias antes ao vencimento" },
        { value: "5", label: "5 Dias antes ao vencimento" },
        { value: "6", label: "6 Dias antes ao vencimento" },
        { value: "7", label: "7 Dias antes ao vencimento" },
        { value: "14", label: "14 Dias antes ao vencimento" },
        { value: "30", label: "30 Dias antes ao vencimento" }
    ],
    validation_type: "string",
    validations: [
        {
            type: "required",
            params: ["É obrigatória a escolha de um dia!"]
        }
    ]
}

const ACTION_WEB_HEADERS: FieldProps = {
    name: "action_web_headers",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "7",
    required: false,
    index: 2,
    title: "Headers",
    description: "Digite os headers da requisição com o formato JSON",
    placeholder: `{ 'Content-Type': 'application/json' }`,
    help_text: "Informe os headers da requisição que será feita. O formato deve ser JSON.",
    turn_on_field: true
}

const ACTION_WEB_BODY: FieldProps = {
    name: "action_web_body",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "7",
    required: false,
    index: 3,
    title: "Body",
    description: "Digite o body da requisição",
    placeholder: `{ 'Nome': 'João Silva' }`,
    help_text: "Informe o body da requisição que será feita. O formato deve ser JSON.",
    turn_on_field: true
}