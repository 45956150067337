import {
    BtnGoToPage,
    BtnUpgrade,
    Container,
    ContainerContent,
    ContainerHeader,
    ContainerHeaderRight,
    ContainerStatsUser,
    CurrentFrequencyDesc,
    CurrentPlan,
    CurrentPlanContainer,
    CurrentPlanDesc,
    ExcededPlanButton,
    ExcededPlanContainer,
    ExcededPlanDesc,
    ExcededPlanIcon,
    StatsItems,
    UpgradeButton
} from "./style";
import React, { useCallback, useEffect, useState } from "react";

import { CompanyPlan } from "../../../interfaces/CompanyPlan";
import ContentLoader from "react-content-loader";
import StatUserItem from "../StatUserItem";
import Title from "../../../components/Title";
import { UserUse } from "../../../interfaces/UserUse";
import api from '../../../services/api';
import { useAuth } from "../../../hooks/auth";
import ChoosePlan from "../../../dialogs/Billing/ChoosePlan";
import { FaExclamationTriangle } from "react-icons/fa";

interface StatsUserProps {
    filter: string;
    hideCurrentPlan?: boolean;
}

interface ModelStatsProps {
    dashboard: string[];
    flow: string[];
    register: string[];
    invite: string[];
}

interface StatItemProps {
    key: string;
    title: string;
    help_text?: string;
    value: string | number;
    target?: string | number;
    bar_value?: number;
    hideTarget?: boolean;
}

const models_stats: ModelStatsProps = {
    dashboard: ['tot_user', 'tot_flow', 'tot_register', 'tot_connections', 'tot_attachments_size'],
    flow: ['tot_flow', 'tot_card_month', 'tot_attachments_size', 'tot_connections'],
    register: ['tot_register', 'tot_register_row', 'tot_attachments_size', 'tot_connections'],
    invite: ['tot_user', 'tot_flow', 'tot_register']
}

interface PlanProps {
    tot_user: number;
    tot_flow: number;
    tot_card_month: number;
    tot_register: number;
    tot_register_row: number;
    tot_connections: number;
    tot_attachments_size: number;
}

const plan_free: PlanProps = {
    tot_user: 5,
    tot_flow: 3,
    tot_card_month: 75,
    tot_register: 5,
    tot_register_row: 500,
    tot_connections: 1,
    tot_attachments_size: 3
}

const plan_plus: PlanProps = {
    tot_user: 0,
    tot_flow: 15,
    tot_card_month: 0,
    tot_register: 10,
    tot_register_row: 3000,
    tot_connections: 10,
    tot_attachments_size: 30
}

const plan_professional: PlanProps = {
    tot_user: 0,
    tot_flow: 0,
    tot_card_month: 0,
    tot_register: 0,
    tot_register_row: 20000,
    tot_connections: 0,
    tot_attachments_size: 100
}

const plan_enterprise: PlanProps = {
    tot_user: 0,
    tot_flow: 0,
    tot_card_month: 0,
    tot_register: 0,
    tot_register_row: 20000,
    tot_connections: 0,
    tot_attachments_size: 100
}

interface StatUserItemProps {
    var: string;
    title: string;
    help_text?: string;
    value: number;
    target?: number;
    bar_value?: number;
    hideTarget?: boolean;
}

const kpis_free: StatUserItemProps[] = [
    {
        var: 'tot_user',
        title: "Usuários",
        help_text: "Número total de usuários na sua empresa que estão cadastrados no Cange.",
        value: 0,
        target: plan_free["tot_user"],
    },
    {
        var: 'tot_flow',
        title: "Fluxos",
        help_text: "Número total de fluxos criados na sua empresa.",
        value: 0,
        target: plan_free["tot_flow"]
    },
    {
        var: 'tot_card_month',
        title: "Cartões Mensais",
        help_text: "Número total de cartões criados no mês atual. Na virada do mês a franquia de cartões mensais é renovada.",
        value: 0,
        target: plan_free["tot_card_month"]
    },
    {
        var: 'tot_register',
        title: "Cadastros",
        help_text: "Número total de cadastros criados na sua empresa.",
        value: 0,
        target: plan_free["tot_register"]
    },
    {
        var: 'tot_register_row',
        title: "Registros",
        help_text: "Número total de registros criados dentro dos cadastros.",
        value: 0,
        target: plan_free["tot_register_row"]
    },
    {
        var: 'tot_connections',
        title: "Conexões",
        help_text: "Número total de conexões de fluxos ou cadastros utilizadas.",
        value: 0,
        target: plan_free["tot_connections"]
    },
    {
        var: 'tot_attachments_size',
        title: "Armazenamento",
        help_text: "Total de dados armazenados em arquivos no Cange.",
        value: 0,
        target: plan_free["tot_attachments_size"]
    },
]

const kpis_plus: StatUserItemProps[] = [
    {
        var: 'tot_user',
        title: "Usuários",
        help_text: "Número total de usuários na sua empresa que estão cadastrados no Cange.",
        value: 0,
        target: plan_plus["tot_user"]
    },
    {
        var: 'tot_flow',
        title: "Fluxos",
        help_text: "Número total de fluxos criados na sua empresa.",
        value: 0,
        target: plan_plus["tot_flow"],
    },
    {
        var: 'tot_card_month',
        title: "Cartões Mensais",
        help_text: "Número total de cartões criados no mês atual. Na virada do mês a franquia de cartões mensais é renovada.",
        value: 0,
        target: plan_plus["tot_card_month"],
        hideTarget: true
    },
    {
        var: 'tot_register',
        title: "Cadastros",
        help_text: "Número total de cadastros criados na sua empresa.",
        value: 0,
        target: plan_plus["tot_register"],
    },
    {
        var: 'tot_register_row',
        title: "Registros",
        help_text: "Número total de registros criados dentro dos cadastros.",
        value: 0,
        target: plan_plus["tot_register_row"]
    },
    {
        var: 'tot_connections',
        title: "Conexões",
        help_text: "Número total de conexões de fluxos ou cadastros utilizadas.",
        value: 0,
        target: plan_plus["tot_connections"],
    },
    {
        var: 'tot_attachments_size',
        title: "Armazenamento",
        help_text: "Total de dados armazenados em arquivos no Cange.",
        value: 0,
        target: plan_plus["tot_attachments_size"]
    },
]


const kpis_professional: StatUserItemProps[] = [
    {
        var: 'tot_user',
        title: "Usuários",
        help_text: "Número total de usuários na sua empresa que estão cadastrados no Cange.",
        value: 0,
        target: plan_professional["tot_user"]
    },
    {
        var: 'tot_flow',
        title: "Fluxos",
        help_text: "Número total de fluxos criados na sua empresa.",
        value: 0,
        target: plan_professional["tot_flow"],
        hideTarget: true
    },
    {
        var: 'tot_card_month',
        title: "Cartões Mensais",
        help_text: "Número total de cartões criados no mês atual. Na virada do mês a franquia de cartões mensais é renovada.",
        value: 0,
        target: plan_professional["tot_card_month"],
        hideTarget: true
    },
    {
        var: 'tot_register',
        title: "Cadastros",
        help_text: "Número total de cadastros criados na sua empresa.",
        value: 0,
        target: plan_professional["tot_register"],
        hideTarget: true
    },
    {
        var: 'tot_register_row',
        title: "Registros",
        help_text: "Número total de registros criados dentro dos cadastros.",
        value: 0,
        target: plan_professional["tot_register_row"],
        hideTarget: true
    },
    {
        var: 'tot_connections',
        title: "Conexões",
        help_text: "Número total de conexões de fluxos ou cadastros utilizadas.",
        value: 0,
        target: plan_professional["tot_connections"],
        hideTarget: true
    },
    {
        var: 'tot_attachments_size',
        title: "Armazenamento",
        help_text: "Total de dados armazenados em arquivos no Cange.",
        value: 0,
        target: plan_professional["tot_attachments_size"]
    },
]

const kpis_enterprise: StatUserItemProps[] = [
    {
        var: 'tot_user',
        title: "Usuários",
        help_text: "Número total de usuários na sua empresa que estão cadastrados no Cange.",
        value: 0,
        target: plan_enterprise["tot_user"]
    },
    {
        var: 'tot_flow',
        title: "Fluxos",
        help_text: "Número total de fluxos criados na sua empresa.",
        value: 0,
        target: plan_enterprise["tot_flow"],
        hideTarget: true
    },
    {
        var: 'tot_card_month',
        title: "Cartões Mensais",
        help_text: "Número total de cartões criados no mês atual. Na virada do mês a franquia de cartões mensais é renovada.",
        value: 0,
        target: plan_enterprise["tot_card_month"],
        hideTarget: true
    },
    {
        var: 'tot_register',
        title: "Cadastros",
        help_text: "Número total de cadastros criados na sua empresa.",
        value: 0,
        target: plan_enterprise["tot_register"],
        hideTarget: true
    },
    {
        var: 'tot_register_row',
        title: "Registros",
        help_text: "Número total de registros criados dentro dos cadastros.",
        value: 0,
        target: plan_enterprise["tot_register_row"],
        hideTarget: true
    },
    {
        var: 'tot_connections',
        title: "Conexões",
        help_text: "Número total de conexões de fluxos ou cadastros utilizadas.",
        value: 0,
        target: plan_enterprise["tot_connections"],
        hideTarget: true
    },
    {
        var: 'tot_attachments_size',
        title: "Armazenamento",
        help_text: "Total de dados armazenados em arquivos no Cange.",
        value: 0,
        target: plan_enterprise["tot_attachments_size"]
    },
]


const LoaderCard = () => (
    <ContentLoader
        speed={2}
        width={230}
        height={66}
        viewBox="0 0 230 66"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="5" ry="5" width="230" height="66" />
    </ContentLoader>
)

const StatsUser: React.FC<StatsUserProps> = ({ filter, hideCurrentPlan }) => {

    const { user } = useAuth();
    const [stats, setStats] = useState<UserUse>();
    const [companyPlan, setCompanyPlan] = useState<CompanyPlan>();
    const [hideUpgrade] = useState<boolean>(hideCurrentPlan !== undefined ? hideCurrentPlan : false);
    const [filterKpi, setFilterKpi] = useState<String[]>();
    const [statsKpis, setStatsKpis] = useState<StatUserItemProps[]>(kpis_free);
    const [loading, setLoading] = useState<boolean>(false);
    const [daysLeft, setDaysLeft] = useState<number>(0);
    const [openBilling, setOpenBilling] = useState(false);

    const [kpis, setKpis] = useState<StatItemProps[]>([]);
    const [excededPlan, setExcededPlan] = useState<boolean>(false);

    const getStatsApi = useCallback(async () => {

        setLoading(true);

        await api
            .get(`/user/stats`)
            .then(response => {

                const apiRet: UserUse[] = response.data;

                if (apiRet !== undefined && apiRet.length > 0) {
                    setStats(apiRet[0]);
                }

            });

        setLoading(false);

    }, []);

    useEffect(() => {

        getStatsApi();

        if (filter !== undefined) {
            setFilterKpi(models_stats[filter as keyof ModelStatsProps]);
        }

    }, [getStatsApi, filter]);

    useEffect(() => {

        if (stats !== undefined) {

            setStatsKpis((state) =>
                state.map((kpi) => {
                    kpi.value = stats[kpi.var as keyof PlanProps];

                    return kpi;
                })
            );

        }

    }, [stats]);

    useEffect(() => {

        if (user.company?.company_plan !== undefined && user.company?.company_plan.length > 0) {
            const newPlan = user.company?.company_plan[0];

            setCompanyPlan(newPlan);

            //Days left - Trial
            if (newPlan.plan_id === 6) {
                const dateEnd = new Date(newPlan.dt_expires);
                const dateNow = new Date();

                const diffTime = dateEnd.getTime() - dateNow.getTime();
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                if (diffDays > 0) {
                    setDaysLeft(diffDays);
                } else {
                    setDaysLeft(0);
                }
            }

            if (newPlan.plan !== undefined) {

                if (newPlan.plan.name === "Free") {

                    setStatsKpis(kpis_free);

                } else if (newPlan.plan.name === "Plus") {

                    let newKpi = kpis_plus;

                    newKpi = newKpi.map((kpi) => {

                        if (kpi.var === "tot_user") {
                            kpi.target = newPlan.users;
                        }

                        return kpi;
                    })

                    setStatsKpis(newKpi);

                } else if (newPlan.plan.name === "Professional") {

                    let newKpi = kpis_professional;

                    newKpi = newKpi.map((kpi) => {

                        if (kpi.var === "tot_user") {
                            kpi.target = newPlan.users;
                        }

                        return kpi;
                    })

                    setStatsKpis(newKpi);


                } else if (newPlan.plan.name === "Enterprise") {

                    let newKpi = kpis_enterprise;

                    newKpi = newKpi.map((kpi) => {

                        if (kpi.var === "tot_user") {
                            kpi.target = newPlan.users;
                        }

                        return kpi;
                    })

                    setStatsKpis(newKpi);

                }
            }

        }

    }, [user]);

    useEffect(() => {

        if (statsKpis !== undefined) {

            let newKpis: StatItemProps[] = [];

            statsKpis
                .filter((stat) => filterKpi?.includes(stat.var))
                .map((stat) => {

                    const bar = stat.target !== undefined && stat.target > 0 ? stat.value / stat.target * 100 : 0;

                    const barReadble = stat.target !== undefined && stat.var === "tot_attachments_size" ? ((stat.value / 1024 / 1024) / stat.target) * 100 : bar;

                    const valReadble = stat.var === "tot_attachments_size" ? (stat.value / 1024 / 1024).toFixed(2) : stat.value;

                    const tgtReadble = stat.var === "tot_attachments_size" ? stat.target + "GB" : stat.target;

                    const newItemKpi: StatItemProps = {
                        key: stat.var,
                        title: stat.title,
                        help_text: stat.help_text,
                        value: valReadble,
                        target: tgtReadble,
                        bar_value: barReadble === undefined || barReadble <= 0 ? 0.01 : barReadble,
                        hideTarget: stat.hideTarget
                    }

                    if (companyPlan !== undefined && companyPlan.plan_id === 1) { // Free

                        if (bar > 100) {
                            setExcededPlan(true);
                        }

                    }

                    newKpis.push(newItemKpi);

                    return stat;
                });

            setKpis(newKpis);

        }

    }, [statsKpis, companyPlan, filterKpi]);

    return (
        <>
            {excededPlan && (
                <ExcededPlanContainer>
                    <ExcededPlanIcon>
                        <FaExclamationTriangle />
                    </ExcededPlanIcon>
                    <ExcededPlanDesc>
                        <span><b>Seu plano atual excedeu a franquia de uso!</b> Faça um upgrade ou ajuste o seu uso para evitar interrupções</span>
                    </ExcededPlanDesc>
                    <ExcededPlanButton>
                        <BtnUpgrade style={{ background: 'white', color: '#f23b5c'}} onClick={() => setOpenBilling(true)}>
                            Fazer Upgrade
                        </BtnUpgrade>
                    </ExcededPlanButton>
                </ExcededPlanContainer>
            )}
            <ContainerStatsUser>

                <ChoosePlan
                    open={openBilling}
                    onClose={() => setOpenBilling(false)}
                    isEndTrial={false}
                />

                {hideUpgrade ? <></> :
                    <Container style={{ width: '200px', marginRight: '15px' }}>
                        <ContainerContent style={{ height: '100%' }}>
                            <CurrentPlanContainer>
                                <CurrentPlanDesc>
                                    {companyPlan !== undefined && companyPlan.plan_id === 6 ?
                                        <span>No seu <u>Trial</u> restam</span> :
                                        "Seu plano atual"
                                    }
                                </CurrentPlanDesc>
                                <CurrentPlan isPaid={companyPlan !== undefined && companyPlan.plan_id !== 1 && companyPlan.plan_id !== 6}>
                                    {companyPlan !== undefined ?
                                        companyPlan.plan_id === 6 ?
                                            <>{daysLeft} {daysLeft === 1 ? "dia " : "dias "}</> :
                                            companyPlan.plan.name :
                                        "Free"
                                    }
                                </CurrentPlan>
                                {companyPlan !== undefined && companyPlan.plan_id !== 1 && companyPlan.plan_id !== 6 ? <></> :
                                    <UpgradeButton>
                                        <BtnUpgrade onClick={() => setOpenBilling(true)}>
                                            Fazer Upgrade
                                        </BtnUpgrade>
                                    </UpgradeButton>
                                }
                                {companyPlan !== undefined && companyPlan.plan_id !== 1 && companyPlan.plan_id !== 6 && companyPlan.plan.frequency !== undefined ?
                                    <CurrentFrequencyDesc>
                                        {companyPlan.plan.frequency === "M" ? "Mensal" : "Anual"}
                                    </CurrentFrequencyDesc> :
                                    <></>
                                }
                            </CurrentPlanContainer>
                        </ContainerContent>
                    </Container>
                }

                <Container style={{ width: 'calc(100% - 215px)' }}>
                    <ContainerContent>
                        <ContainerHeader>
                            <Title
                                title={"Insights"}
                                required={false}
                                help_text={"Insights auxiliam os usuários do Cange a entender o suas métricas de uso na ferramenta"}
                            />

                            <ContainerHeaderRight style={{ width: 'calc(100% - 70px)' }}>
                                {companyPlan === undefined || companyPlan.plan_id === 1 ?
                                    <BtnGoToPage onClick={() => setOpenBilling(true)}>Ver todos Planos</BtnGoToPage> :
                                    <></>
                                }
                            </ContainerHeaderRight>

                        </ContainerHeader>
                        <StatsItems>
                            {loading ? (
                                <>
                                    <div style={{ marginRight: '15px' }}>
                                        <LoaderCard key={"1"} />
                                    </div>
                                    <div style={{ marginRight: '15px' }}>
                                        <LoaderCard key={"2"} />
                                    </div>
                                    <div style={{ marginRight: '15px' }}>
                                        <LoaderCard key={"3"} />
                                    </div>
                                </>
                            ) :
                                kpis?.map((kpi) => {
                                    return (
                                        <StatUserItem
                                            key={kpi.key}
                                            title={kpi.title}
                                            help_text={kpi.help_text}
                                            value={kpi.value}
                                            target={kpi.target}
                                            bar_value={kpi.bar_value}
                                            hideTarget={kpi.hideTarget}
                                        />
                                    )
                                })}
                        </StatsItems>
                    </ContainerContent>
                </Container>
            </ContainerStatsUser>
        </>
    );

}

export default StatsUser;