import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    AutomationConfigGroup,
    AutomationConfigItem,
    AutomationConfigItemDescription,
    AutomationConfigItemIcon,
    AutomationConfigItems,
    ContainerCloseButton,
    ButtonClose,
    Container,
    ContainerConfigRightHeader,
    ContainerConfigRightHeaderSearch,
    ContainerConfigRightHeaderTitle,
    ContainerLoader,
    Animation,
    DividerMenu,
    ContainerForm,
    FilterComboContainer,
    FilterComboContainerTitle,
    FilterComboContainerDescription,
    FilterComboContainerButton,
    ContainerFormLabel
} from "./style";
import { BsArrowBarRight } from "react-icons/bs";
import Trigger from "../../../../interfaces/Trigger";
import Action from "../../../../interfaces/Action";
import lottie from "lottie-web";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import getConfigFields, { ConfigFieldAutomation } from "./getConfigFields";
import { FieldOptionsProps, FieldProps, Fields, getFieldObject } from "../../../../components/Forms/Fields/FieldBuilder";
import FormBuilder from "../../../../components/Forms/FormBuilder";
import { FormHandles } from "@unform/core";
import { Flow } from "../../../../interfaces/Flow";
import api from "../../../../services/api";
import { useToast } from "../../../../hooks/toast";
import { Condition } from "../../../../interfaces/Condition";
import getCustomFieldsFiltered from "../../../../components/Forms/Fields/Components/CustomSelect/getCustomFieldsFiltered";
import { RiFlowChart } from "react-icons/ri";
import { FaCalendarAlt, FaCalendarWeek, FaClock, FaHashtag, FaLink, FaPlus, FaUser } from "react-icons/fa";
import { MdOutlineFilterList } from "react-icons/md";
import ConditionBox from "./ConditionBox";
import { FlowTag } from "../../../../interfaces/FlowTag";
import { User } from "../../../../interfaces/User";

const LABEL_FIELD: FieldProps = {
    name: "label_field",
    type: Fields.CUSTOM_SELECT_FIELD,
    variation: "1",
    required: false,
    max_length: 100,
    index: 0,
    title: "Etiqueta",
    description: "Crie uma etiqueta para identificar este item",
    placeholder: "Digite aqui...",
    help_text: "Utilize este campo para facilitar o entendimento da sua automação",
    turn_on_field: false
}

interface LabeFieldProps {
    label_field?: string;
}

interface SelectMenuProps {
    openConfig: boolean;
    type: 'trigger' | 'action' | 'condition';
    flow?: Flow;
    selectedItem: Trigger | Action | Condition;
    filterSchema: string | undefined;
    filterStateMessage: string | undefined;
    setFilterSchema: React.Dispatch<React.SetStateAction<string | undefined>>;
    setFilterStateMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
    handleSaveMenuConfig: (type: 'trigger' | 'action' | 'condition', selectedItem: Trigger | Action | Condition, data?: object[]) => void;
    handleSaveDescription: (type: 'trigger' | 'action' | 'condition', selectedItem: Trigger | Action | Condition, description: string) => void;
    handleCloseMenuConfig: () => void;
}

const ConfigMenu: React.FC<SelectMenuProps> = ({ openConfig, type, selectedItem, flow, filterSchema, filterStateMessage, setFilterSchema, setFilterStateMessage, handleSaveMenuConfig, handleCloseMenuConfig, handleSaveDescription }) => {

    const { addToast } = useToast();

    const lottieContainer = useRef<HTMLDivElement>(null);

    //Form
    const formRef = useRef<FormHandles>(null);
    const [defaultValue, setDefaultValue] = useState<object[]>();
    const [fieldsConfig, setFieldsConfig] = useState<FieldProps[]>([]);

    //Form Label
    const formRefLabel = useRef<FormHandles>(null);
    const [defaultValueLabel, setDefaultValueLabel] = useState<object[]>();

    const [loading, setLoading] = useState<boolean>();
    const [openConditionBox, setOpenConditionBox] = useState<boolean>(false);

    //Auxiliar variables
    const [flowIdSelected, setFlowIdSelected] = useState<number>();
    const [flowIdOld, setFlowIdOld] = useState<number>();

    const handleSubmitForm = useCallback(async (data: object[]) => {

        handleSaveMenuConfig(type, selectedItem, data);

    }, [handleSaveMenuConfig, selectedItem, type]);

    const handleSubmitFormLabel = useCallback(async (data: object[]) => {

        if (data !== undefined && data !== null) {

            const dataNormalized = data as unknown as LabeFieldProps;

            if (dataNormalized.label_field !== undefined && dataNormalized.label_field !== null && dataNormalized.label_field !== "") {
                handleSaveDescription(type, selectedItem, dataNormalized.label_field);
            }

        }

    }, [handleSaveDescription, selectedItem, type]);

    const handleSubmitPage = useCallback(async () => {
        if (formRef.current !== undefined) {
            formRef.current?.submitForm();

            //Update the fields in execution time
            if (formRef.current !== null) {
                const data = formRef.current.getData();

                //Valid if the data is not empty and run into all fields
                if (data !== undefined && data !== null) {

                    const arrFlowId = Object.entries(data).filter(function (key) { return key.includes('action_flow_id') || key.includes('trigger_flow_target_id') });

                    if (arrFlowId !== undefined && arrFlowId.length > 0) {

                        //Get the value of the selected flow
                        const flowId = arrFlowId[0][1] as number[];

                        if (flowId !== undefined && flowId !== null && flowId.length > 0) {

                            if (Number(flowId) !== flowIdSelected) {
                                setFlowIdSelected(Number(flowId));
                            }

                        }

                    }

                }

            }

        }
    }, [flowIdSelected]);

    const handleSubmitPageLabel = useCallback(async () => {
        if (formRefLabel.current !== undefined) {
            formRefLabel.current?.submitForm();
        }
    }, []);

    const loadTriggerFields = useCallback(async (configFields: ConfigFieldAutomation, id_flow?: number) => {

        setLoading(true);

        let newFields = getConfigFields(configFields, id_flow);

        //Run the fields to customize
        if (newFields !== undefined && newFields.length > 0) {
            for (let index = 0; index < newFields.length; index++) {
                const field = newFields[index];

                if (field.name === 'trigger_flow_step_id' || field.name === 'action_flow_step_id') {

                    if (id_flow !== undefined) {

                        await api.get(`/flow/`, {
                            params: {
                                id_flow: id_flow
                            }
                        }).then(response => {
                            if (response.data !== null) {

                                let retFlow: Flow = response.data;

                                if (retFlow !== undefined && retFlow.flow_steps !== undefined && retFlow.flow_steps.length > 0) {

                                    const steps = retFlow.flow_steps.map(step => {
                                        return {
                                            value: String(step.id_step),
                                            label: step.name,
                                            iconDynamic: step.icon,
                                            color: step.color,
                                            description: step.description,
                                            group: "Etapas do Fluxo",
                                            groupIndex: step.index
                                        }
                                    });

                                    field.options = steps;

                                }
                            }
                        }).catch(error => {
                            addToast({
                                type: 'error',
                                title: 'Não foi possível carregar as etapas disponíveis',
                                description: 'Ocorreu um erro ao carregar as etapas disponíveis!',
                            });
                        });
                    }

                } else if (field.name === 'trigger_field_id') {

                    if (id_flow !== undefined) {

                        await api.get(`/field/by-flow`, {
                            params: {
                                flow_id: id_flow,
                                withChildren: 'false',
                                withStep: 'true'
                            }
                        }).then(response => {
                            if (response.data !== null) {

                                let retFields: FieldProps[] = response.data;

                                if (retFields !== undefined) {

                                    const fields = retFields.map(field => {
                                        return {
                                            value: String(field.id_field),
                                            label: field.title,
                                            icon: getFieldObject(field.type).icon,
                                            color: getFieldObject(field.type).color,
                                            description: getFieldObject(field.type).title,
                                            type: getFieldObject(field.type).type,
                                            group: field.flow_step?.name !== undefined ? field.flow_step?.name : "Formulário Inicial",
                                            groupIndex: field.flow_step?.index !== undefined ? field.flow_step?.index : -1
                                        }
                                    });

                                    field.options = fields;

                                }
                            }
                        }).catch(error => {
                            addToast({
                                type: 'error',
                                title: 'Não foi possível carregar os campos disponíveis',
                                description: 'Ocorreu um erro ao carregar os campos disponíveis!',
                            });
                        });

                    }
                } else if (field.name === 'action_flow_field_id') {

                    if (id_flow !== undefined) {

                        await api.get(`/field/by-flow`, {
                            params: {
                                flow_id: id_flow,
                                withChildren: 'false',
                                withStep: 'true'
                            }
                        }).then(response => {
                            if (response.data !== null) {

                                let retFields: FieldProps[] = response.data;

                                if (retFields !== undefined) {

                                    //Filter allowed types
                                    retFields = retFields.filter(field => {
                                        if (field.type === 'COMBO_BOX_FLOW_FIELD') {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    });

                                    const fields = retFields.map(field => {
                                        return {
                                            value: String(field.id_field),
                                            label: field.title,
                                            icon: getFieldObject(field.type).icon,
                                            color: getFieldObject(field.type).color,
                                            description: getFieldObject(field.type).title,
                                            type: getFieldObject(field.type).type,
                                            group: field.flow_step?.name !== undefined ? field.flow_step?.name : "Formulário Inicial",
                                            groupIndex: field.flow_step?.index !== undefined ? field.flow_step?.index : -1,
                                            flow_id: field.flow_id
                                        }
                                    });

                                    field.options = fields;

                                }
                            }
                        }).catch(error => {
                            addToast({
                                type: 'error',
                                title: 'Não foi possível carregar os campos disponíveis',
                                description: 'Ocorreu um erro ao carregar os campos disponíveis!',
                            });
                        });

                    }

                } else if (field.name === 'action_email_attachment') {

                    if (id_flow !== undefined) {

                        await api.get(`/field/by-flow`, {
                            params: {
                                flow_id: id_flow,
                                withChildren: 'false',
                                withStep: 'true'
                            }
                        }).then(response => {
                            if (response.data !== null) {

                                let retFields: FieldProps[] = response.data;

                                if (retFields !== undefined) {

                                    //Filter allowed types
                                    retFields = retFields.filter(field => {
                                        if (field.type === 'INPUT_ATTACH_FIELD') {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    });

                                    const fields = retFields.map(field => {
                                        return {
                                            value: String(field.id_field),
                                            label: field.title,
                                            icon: getFieldObject(field.type).icon,
                                            color: getFieldObject(field.type).color,
                                            description: getFieldObject(field.type).title,
                                            type: getFieldObject(field.type).type,
                                            group: field.flow_step?.name !== undefined ? field.flow_step?.name : "Formulário Inicial",
                                            groupIndex: field.flow_step?.index !== undefined ? field.flow_step?.index : -1
                                        }
                                    });

                                    field.options = fields;

                                }
                            }
                        }).catch(error => {
                            addToast({
                                type: 'error',
                                title: 'Não foi possível carregar os campos disponíveis',
                                description: 'Ocorreu um erro ao carregar os campos disponíveis!',
                            });
                        });

                    }

                } else if (field.name === 'trigger_user_id' || field.name === 'action_user_id') {

                    if (id_flow !== undefined) {

                        await api.get(`/user/by-flow`, {
                            params: {
                                id_flow: id_flow
                            }
                        }).then(response => {
                            if (response.data !== null) {

                                let retUsers: User[] = response.data;

                                if (retUsers !== undefined) {

                                    const users = retUsers.map(user => {
                                        return {
                                            value: String(user.id_user),
                                            label: user.name,
                                            iconDynamic: "FaUser",
                                            color: user.color !== undefined && user.color !== null ? user.color : "#9e37ed",
                                            description: user.email,
                                            group: "Usuários do Fluxo",
                                            groupIndex: 0
                                        }
                                    });

                                    field.options = users;

                                }
                            }
                        }).catch(error => {
                            addToast({
                                type: 'error',
                                title: 'Não foi possível carregar os usuários disponíveis',
                                description: 'Ocorreu um erro ao carregar os usuários disponíveis!',
                            });
                        });

                    }

                } else if (field.name === 'trigger_time_month') {

                    const options = [
                        {
                            value: '0',
                            label: 'Janeiro',
                            icon: FaCalendarAlt,
                            color: '#ffc107',
                            description: 'Mês',
                            type: 'MONTH_FIELD',
                            group: 'Mês',
                            groupIndex: 0
                        },
                        {
                            value: '1',
                            label: 'Fevereiro',
                            icon: FaCalendarAlt,
                            color: '#ffc107',
                            description: 'Mês',
                            type: 'MONTH_FIELD',
                            group: 'Mês',
                            groupIndex: 0
                        },
                        {
                            value: '2',
                            label: 'Março',
                            icon: FaCalendarAlt,
                            color: '#ffc107',
                            description: 'Mês',
                            type: 'MONTH_FIELD',
                            group: 'Mês',
                            groupIndex: 0
                        },
                        {
                            value: '3',
                            label: 'Abril',
                            icon: FaCalendarAlt,
                            color: '#ffc107',
                            description: 'Mês',
                            type: 'MONTH_FIELD',
                            group: 'Mês',
                            groupIndex: 0
                        },
                        {
                            value: '4',
                            label: 'Maio',
                            icon: FaCalendarAlt,
                            color: '#ffc107',
                            description: 'Mês',
                            type: 'MONTH_FIELD',
                            group: 'Mês',
                            groupIndex: 0
                        },
                        {
                            value: '5',
                            label: 'Junho',
                            icon: FaCalendarAlt,
                            color: '#ffc107',
                            description: 'Mês',
                            type: 'MONTH_FIELD',
                            group: 'Mês',
                            groupIndex: 0
                        },
                        {
                            value: '6',
                            label: 'Julho',
                            icon: FaCalendarAlt,
                            color: '#ffc107',
                            description: 'Mês',
                            type: 'MONTH_FIELD',
                            group: 'Mês',
                            groupIndex: 0
                        },
                        {
                            value: '7',
                            label: 'Agosto',
                            icon: FaCalendarAlt,
                            color: '#ffc107',
                            description: 'Mês',
                            type: 'MONTH_FIELD',
                            group: 'Mês',
                            groupIndex: 0
                        },
                        {
                            value: '8',
                            label: 'Setembro',
                            icon: FaCalendarAlt,
                            color: '#ffc107',
                            description: 'Mês',
                            type: 'MONTH_FIELD',
                            group: 'Mês',
                            groupIndex: 0
                        },
                        {
                            value: '9',
                            label: 'Outubro',
                            icon: FaCalendarAlt,
                            color: '#ffc107',
                            description: 'Mês',
                            type: 'MONTH_FIELD',
                            group: 'Mês',
                            groupIndex: 0
                        },
                        {
                            value: '10',
                            label: 'Novembro',
                            icon: FaCalendarAlt,
                            color: '#ffc107',
                            description: 'Mês',
                            type: 'MONTH_FIELD',
                            group: 'Mês',
                            groupIndex: 0
                        },
                        {
                            value: '11',
                            label: 'Dezembro',
                            icon: FaCalendarAlt,
                            color: '#ffc107',
                            description: 'Mês',
                            type: 'MONTH_FIELD',
                            group: 'Mês',
                            groupIndex: 0
                        }
                    ]

                    field.options = options;

                } else if (field.name === 'trigger_time_monthday') {

                    //Create an array of days from 1 to 31
                    let options = [];

                    options.push({
                        value: '-1',
                        label: 'Primeiro dia do mês',
                        icon: FaCalendarAlt,
                        color: '#ffc107',
                        description: 'Dia do mês',
                        type: 'MONTHDAY_FIELD',
                        group: 'Dias funcionais',
                        groupIndex: 0
                    });

                    options.push({
                        value: '-2',
                        label: 'Último dia do mês',
                        icon: FaCalendarAlt,
                        color: '#ffc107',
                        description: 'Dia do mês',
                        type: 'MONTHDAY_FIELD',
                        group: 'Dias funcionais',
                        groupIndex: 0
                    });

                    for (let i = 1; i <= 31; i++) {
                        options.push({
                            value: String(i),
                            label: 'Dia ' + String(i),
                            icon: FaCalendarAlt,
                            color: '#ffc107',
                            description: 'Dia do mês',
                            type: 'MONTHDAY_FIELD',
                            group: 'Dia do mês',
                            groupIndex: 1
                        });
                    }

                    field.options = options;

                } else if (field.name === 'trigger_time_weekday') {

                    const options = [
                        {
                            value: '1',
                            label: 'Segunda-feira',
                            icon: FaCalendarWeek,
                            color: '#ffc107',
                            description: 'Dia da semana',
                            type: 'WEEKDAY_FIELD',
                            group: 'Dia útil',
                            groupIndex: 0,
                        },
                        {
                            value: '2',
                            label: 'Terça-feira',
                            icon: FaCalendarWeek,
                            color: '#ffc107',
                            description: 'Dia da semana',
                            type: 'WEEKDAY_FIELD',
                            group: 'Dia útil',
                            groupIndex: 0,
                        },
                        {
                            value: '3',
                            label: 'Quarta-feira',
                            icon: FaCalendarWeek,
                            color: '#ffc107',
                            description: 'Dia da semana',
                            type: 'WEEKDAY_FIELD',
                            group: 'Dia útil',
                            groupIndex: 0,
                        },
                        {
                            value: '4',
                            label: 'Quinta-feira',
                            icon: FaCalendarWeek,
                            color: '#ffc107',
                            description: 'Dia da semana',
                            type: 'WEEKDAY_FIELD',
                            group: 'Dia útil',
                            groupIndex: 0,
                        },
                        {
                            value: '5',
                            label: 'Sexta-feira',
                            icon: FaCalendarWeek,
                            color: '#ffc107',
                            description: 'Dia da semana',
                            type: 'WEEKDAY_FIELD',
                            group: 'Dia útil',
                            groupIndex: 0,
                        },
                        {
                            value: '6',
                            label: 'Sábado',
                            icon: FaCalendarWeek,
                            color: '#ffc107',
                            description: 'Dia da semana',
                            type: 'WEEKEND_FIELD',
                            group: 'Fim de semana',
                            groupIndex: 1,
                        },
                        {
                            value: '0',
                            label: 'Domingo',
                            icon: FaCalendarWeek,
                            color: '#ffc107',
                            description: 'Dia da semana',
                            type: 'WEEKEND_FIELD',
                            group: 'Fim de semana',
                            groupIndex: 1,
                        }
                    ]

                    field.options = options;

                } else if (field.name === 'trigger_time_hour') {

                    //gerar um array de 00:00 -> 23:00 de hora em hora
                    let options = [];

                    for (let i = 0; i < 24; i++) {
                        let hour = i < 10 ? '0' + i : i;

                        options.push({
                            value: hour + ':00',
                            label: hour + ':00',
                            icon: FaClock,
                            color: '#ffc107',
                            description: 'Horas',
                            type: 'TIME_FIELD',
                            group: i < 6 ? "Madrugada" : i < 13 ? "Manhã" : i < 19 ? "Tarde" : "Noite",
                            groupIndex: i < 5 ? 0 : i < 12 ? 1 : i < 18 ? 2 : 3
                        });

                    }

                    field.options = options;

                } else if (field.name === 'action_flow_id' || field.name === 'trigger_flow_target_id') {

                    await api.get(`/flow/my-flows`, {
                    }).then(response => {
                        if (response.data !== null) {

                            let retFlow: Flow[] = response.data;

                            if (retFlow !== undefined && retFlow.length > 0) {

                                let flows = retFlow.map(flow => {
                                    return {
                                        value: String(flow.id_flow),
                                        label: flow.name,
                                        iconDynamic: flow.icon,
                                        color: flow.color,
                                        description: 'Fluxo',
                                        group: "Meus Fluxos",
                                        groupIndex: 0
                                    }
                                });

                                if (field.name === 'trigger_flow_target_id') { //Remove the current flow from the list
                                    flows = flows.filter(flow => flow.value !== String(id_flow));
                                }

                                field.options = flows;

                            }
                        }
                    }).catch(error => {
                        addToast({
                            type: 'error',
                            title: 'Não foi possível carregar as etapas disponíveis',
                            description: 'Ocorreu um erro ao carregar as etapas disponíveis!',
                        });
                    });

                } else if (field.name === 'trigger_tag_id' || field.name === 'action_tag_id') {

                    await api.get(`/flow-tag/by-flow`, {
                        params: {
                            flow_id: id_flow
                        }
                    }).then(response => {
                        if (response.data !== null) {

                            let retFlowTag: FlowTag[] = response.data;

                            if (retFlowTag !== undefined && retFlowTag.length > 0) {

                                const tags = retFlowTag.map(tag => {
                                    return {
                                        value: String(tag.id_flow_tag),
                                        label: tag.description !== undefined && tag.description !== null && tag.description !== "" ? tag.description : "Etiqueta",
                                        iconDynamic: "FaTag",
                                        color: tag.color,
                                        description: 'Etiqueta',
                                        group: "Etiquetas do fluxo",
                                        groupIndex: 0
                                    }
                                });

                                field.options = tags;

                            }
                        }
                    }).catch(error => {
                        addToast({
                            type: 'error',
                            title: 'Não foi possível carregar as etiquetas disponíveis',
                            description: 'Ocorreu um erro ao carregar as etiquetas disponíveis!',
                        });
                    });

                } else if (field.name === 'action_new_card') {

                    if (flow !== undefined && flow.id_flow !== undefined) {
                        field.flow_id = flow.id_flow;
                    }

                } else if (field.name === 'trigger_flow_step_target_id') {
                } else if (field.name === 'action_ai_field_id') {

                    if (id_flow !== undefined) {

                        await api.get(`/field/by-flow`, {
                            params: {
                                flow_id: id_flow,
                                withChildren: 'false',
                                withStep: 'true'
                            }
                        }).then(response => {
                            if (response.data !== null) {

                                let retFields: FieldProps[] = response.data;

                                if (retFields !== undefined) {

                                    //Filter allowed types to fill with AI
                                    retFields = retFields.filter(field => {
                                        if (field.type === 'TEXT_SHORT_FIELD' ||
                                            field.type === 'TEXT_LONG_FIELD' ||
                                            field.type === 'INPUT_RICH_TEXT_FIELD' ||
                                            field.type === 'COMBO_BOX_FIELD' ||
                                            field.type === 'RADIO_BOX_FIELD' ||
                                            field.type === 'CHECK_BOX_FIELD' ||
                                            field.type === 'COMBO_BOX_REGISTER_FIELD'
                                        ) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    });

                                    let options = retFields.map(field => {
                                        return {
                                            value: String(field.id_field),
                                            label: field.title,
                                            icon: getFieldObject(field.type).icon,
                                            color: getFieldObject(field.type).color,
                                            description: getFieldObject(field.type).title,
                                            type: getFieldObject(field.type).type,
                                            group: field.flow_step?.name !== undefined ? field.flow_step?.name : "Formulário Inicial",
                                            groupIndex: field.flow_step?.index !== undefined ? field.flow_step?.index : -1
                                        } as FieldOptionsProps
                                    });

                                    field.options = options;

                                }
                            }
                        }).catch(error => {
                            addToast({
                                type: 'error',
                                title: 'Não foi possível carregar os campos disponíveis',
                                description: 'Ocorreu um erro ao carregar os campos disponíveis!',
                            });
                        });

                    }

                } else if (field.type === "CUSTOM_SELECT_FIELD") {

                    if (id_flow !== undefined) {

                        await api.get(`/field/by-flow`, {
                            params: {
                                flow_id: id_flow,
                                withChildren: 'true',
                                withStep: 'true'
                            }
                        }).then(response => {
                            if (response.data !== null) {

                                let retFields: FieldProps[] = response.data as FieldProps[];

                                if (retFields !== undefined) {

                                    retFields = getCustomFieldsFiltered(field, retFields);

                                    //Filter the same type of field
                                    let options = retFields.filter(fieldItem => {
                                        if (fieldItem.type !== 'COMBO_BOX_REGISTER_FIELD' && fieldItem.type !== 'COMBO_BOX_FLOW_FIELD') {
                                            return true;
                                        } else {
                                            return false
                                        }
                                    }).map(fieldItem => {
                                        return {
                                            value: String(fieldItem.id_field),
                                            label: fieldItem.title,
                                            icon: getFieldObject(fieldItem.type).icon,
                                            color: getFieldObject(fieldItem.type).color,
                                            description: getFieldObject(fieldItem.type).title,
                                            type: getFieldObject(fieldItem.type).type,
                                            group: fieldItem.flow_step?.name !== undefined ? fieldItem.flow_step?.name : "Formulário Inicial",
                                            groupIndex: fieldItem.flow_step?.index !== undefined ? fieldItem.flow_step?.index : -1
                                        } as FieldOptionsProps
                                    });

                                    //Run into children fields and add just the same type of field children
                                    retFields.forEach(fieldItem => {
                                        if (fieldItem.field_children !== undefined && fieldItem.field_children.length > 0) {
                                            fieldItem.field_children.forEach(child => {
                                                if (child.type !== 'COMBO_BOX_REGISTER_FIELD' && child.type !== 'COMBO_BOX_FLOW_FIELD') {
                                                    options.push({
                                                        value: String(fieldItem.id_field + '->' + child.id_field),
                                                        label: child.title,
                                                        icon: getFieldObject(child.type).icon,
                                                        color: getFieldObject(child.type).color,
                                                        description: getFieldObject(child.type).title,
                                                        type: getFieldObject(child.type).type,
                                                        group: fieldItem.title + " (" + getFieldObject(fieldItem.type).title + ") - " + (fieldItem.flow_step?.name !== undefined ? fieldItem.flow_step?.name : "Formulário Inicial"),
                                                        groupIndex: fieldItem.flow_step?.index !== undefined ? fieldItem.flow_step?.index : -1
                                                    } as FieldOptionsProps);
                                                }
                                            });
                                        }
                                    });

                                    field.options = options;

                                }

                            }
                        }).catch(error => {
                            addToast({
                                type: 'error',
                                title: 'Não foi possível carregar os campos disponíveis',
                                description: 'Ocorreu um erro ao carregar os campos disponíveis!',
                            });
                        });

                    }

                }

                //Default Fields
                let newOpts: FieldOptionsProps[] = [];

                //Step
                if (field.name === 'action_email_message' || field.name === 'action_web_body') {

                    const newOpt = {
                        value: '-2',
                        label: 'Nome da Etapa',
                        icon: RiFlowChart,
                        color: getFieldObject('TEXT_SHORT_FIELD').color,
                        description: 'Etapa',
                        type: getFieldObject('TEXT_SHORT_FIELD').type,
                        group: "Cartão",
                        groupIndex: -2
                    } as FieldOptionsProps

                    newOpts.push(newOpt);

                }

                //Name creator
                if (field.name === 'action_email_message' || field.name === 'action_web_body') {

                    const newOpt = {
                        value: '-3',
                        label: 'Criador do cartão',
                        icon: FaUser,
                        color: getFieldObject('TEXT_SHORT_FIELD').color,
                        description: 'Usuário',
                        type: getFieldObject('TEXT_SHORT_FIELD').type,
                        group: "Cartão",
                        groupIndex: -2
                    } as FieldOptionsProps

                    newOpts.push(newOpt);

                }

                //E-mail criator
                if (field.name === 'action_email_to' || field.name === 'action_email_cc' || field.name === 'action_email_cco' || field.name === 'action_email_message' || field.name === 'action_web_body') {

                    const newOpt = {
                        value: '-4',
                        label: 'E-mail do criador do cartão',
                        icon: getFieldObject('MAIL_FIELD').icon,
                        color: getFieldObject('MAIL_FIELD').color,
                        description: 'Usuário',
                        type: getFieldObject('MAIL_FIELD').type,
                        group: "Cartão",
                        groupIndex: -2
                    } as FieldOptionsProps

                    newOpts.push(newOpt);

                }

                //Card - Dt created
                if (field.name === 'action_email_message' || field.name === 'action_web_body') {

                    const newOpt = {
                        value: '-5',
                        label: 'Data da Criação',
                        icon: getFieldObject('DATE_PICKER_FIELD').icon,
                        color: getFieldObject('DATE_PICKER_FIELD').color,
                        description: 'Data',
                        type: getFieldObject('DATE_PICKER_FIELD').type,
                        group: "Cartão",
                        groupIndex: -2
                    } as FieldOptionsProps

                    newOpts.push(newOpt);

                }

                //Card - Dt due
                if (field.name === 'action_email_message' || field.name === 'action_web_body') {

                    const newOpt = {
                        value: '-6',
                        label: 'Data do Vencimento',
                        icon: getFieldObject('DATE_PICKER_FIELD').icon,
                        color: getFieldObject('DATE_PICKER_FIELD').color,
                        description: 'Data',
                        type: getFieldObject('DATE_PICKER_FIELD').type,
                        group: "Cartão",
                        groupIndex: -2
                    } as FieldOptionsProps

                    newOpts.push(newOpt);

                }

                //Card - User Assigned
                if (field.name === 'action_email_message' || field.name === 'action_web_body') {

                    const newOpt = {
                        value: '-7',
                        label: 'Responsável pelo cartão',
                        icon: FaUser,
                        color: getFieldObject('TEXT_SHORT_FIELD').color,
                        description: 'Usuário',
                        type: getFieldObject('TEXT_SHORT_FIELD').type,
                        group: "Cartão",
                        groupIndex: -2
                    } as FieldOptionsProps

                    newOpts.push(newOpt);

                }

                //Card - User Assigned E-mail
                if (field.name === 'action_email_message' || field.name === 'action_email_to' || field.name === 'action_email_cc' || field.name === 'action_email_cco' || field.name === 'action_web_body') {

                    const newOpt = {
                        value: '-8',
                        label: 'E-mail do responsável pelo cartão',
                        icon: getFieldObject('MAIL_FIELD').icon,
                        color: getFieldObject('MAIL_FIELD').color,
                        description: 'Usuário',
                        type: getFieldObject('MAIL_FIELD').type,
                        group: "Cartão",
                        groupIndex: -2
                    } as FieldOptionsProps

                    newOpts.push(newOpt);

                }

                //Public link to answer the current step
                if (field.name === 'action_email_message' || field.name === 'action_web_body') {

                    const newOpt = {
                        value: '-9',
                        label: 'Botão de link público da etapa',
                        icon: FaLink,
                        color: getFieldObject('LINK_FIELD').color,
                        description: 'Botão',
                        type: getFieldObject('LINK_FIELD').type,
                        group: "Cartão",
                        groupIndex: -2
                    } as FieldOptionsProps

                    newOpts.push(newOpt);

                }

                //Id Card
                if (field.name === 'action_email_message' || field.name === 'action_web_body') {

                    const newOpt = {
                        value: '-10',
                        label: 'Identificador do cartão',
                        icon: FaHashtag,
                        color: getFieldObject('ID_FIELD').color,
                        description: 'ID',
                        type: getFieldObject('ID_FIELD').type,
                        group: "Cartão",
                        groupIndex: -2
                    } as FieldOptionsProps

                    newOpts.push(newOpt);

                }

                //Edit the field and add the new option
                if (field !== undefined && field.options !== undefined && field.options !== null && field.options.length > 0) {
                    field.options = field.options.concat(newOpts);
                } else if (field !== undefined) {
                    field.options = newOpts;
                }

            }
        }

        setFieldsConfig(newFields);
        setLoading(false);

    }, [addToast, flow]);

    useEffect(() => {

        if (openConfig) {

            if (type === 'trigger') {

                const trigger = selectedItem as Trigger;

                //Check if has a payload to load
                if (selectedItem !== undefined && selectedItem.config_fields_values !== undefined && selectedItem.config_fields_values !== null) {

                    const newData = JSON.parse(selectedItem.config_fields_values) as object[];

                    setDefaultValue(newData);

                } else {
                    setDefaultValue([]);
                }

                if (trigger.config_fields !== undefined && trigger.config_fields !== null) {

                    const configFields = JSON.parse(trigger.config_fields) as ConfigFieldAutomation;

                    loadTriggerFields(configFields, flow?.id_flow);

                } else {
                    setFieldsConfig([]);
                    handleSaveMenuConfig(type, selectedItem);
                }

            } else if (type === 'action') {

                const action = selectedItem as Action;

                const configFields = JSON.parse(action.config_fields) as ConfigFieldAutomation;

                //Check if has a payload to load
                if (selectedItem !== undefined && selectedItem.config_fields_values !== undefined && selectedItem.config_fields_values !== null) {

                    const newData = JSON.parse(selectedItem.config_fields_values) as object[];

                    setDefaultValue(newData);

                } else {
                    setDefaultValue([]);
                }

                if (selectedItem.config_description !== undefined && selectedItem.config_description !== null) {
                    const newData = { label_field: selectedItem.config_description } as unknown as object[];

                    setDefaultValueLabel(newData);
                }

                loadTriggerFields(configFields, flow?.id_flow);

            } else if (type === 'condition') {

                const condition = selectedItem as Condition;

                //Check if has a payload to load
                if (condition !== undefined && condition.config_fields_values !== undefined && condition.config_fields_values !== null) {

                    const newData = JSON.parse(condition.config_fields_values) as { name: string, value: string | undefined }[];

                    let conditionObj = newData.find(obj => obj.name === 'condition');

                    if (conditionObj !== undefined) {
                        setFilterSchema(conditionObj.value as string);
                    }

                    let nameObj = newData.find(obj => obj.name === 'name');

                    if (nameObj !== undefined) {
                        setFilterStateMessage(nameObj.value as string);
                    }

                } else {
                    setDefaultValue([]);
                    setFilterStateMessage(undefined);
                    setFilterSchema(undefined);
                }

                if (selectedItem.config_description !== undefined && selectedItem.config_description !== null) {
                    const newData = { label_field: selectedItem.config_description } as unknown as object[];

                    setDefaultValueLabel(newData);
                } else {
                    setDefaultValueLabel([]);
                }

            }

        }

    }, [openConfig, type, selectedItem, flow?.id_flow, loadTriggerFields, handleSaveMenuConfig, setFilterSchema, setFilterStateMessage]);

    useEffect(() => {

        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../../../assets/lottie/loader.json')
            });
        }

    }, [loading]);

    useEffect(() => {

        if (type === 'condition') {
            if (!openConditionBox && filterSchema !== undefined && filterSchema !== null && filterSchema !== "") {

                handleSaveMenuConfig(type, selectedItem, [{ name: 'condition', value: filterSchema }, { name: 'name', value: filterStateMessage }]);

            } else {

                handleSaveMenuConfig(type, selectedItem, undefined);

            }
        }

    }, [filterSchema, filterStateMessage, openConditionBox, type, selectedItem, handleSaveMenuConfig]);

    useEffect(() => {

        if (flowIdSelected !== undefined && flowIdSelected !== flowIdOld) {

            const hasActionNewCard = fieldsConfig.find(field => field.name === 'action_new_card');
            const hasFlowStepTarget = fieldsConfig.find(field => field.name === 'trigger_flow_step_target_id');

            let newOptions: FieldOptionsProps[] = [];

            if (hasFlowStepTarget !== undefined) {

                api.get(`/flow/`, {
                    params: {
                        id_flow: flowIdSelected
                    }
                }).then(response => {
                    if (response.data !== null) {

                        let retFlow: Flow = response.data;

                        if (retFlow !== undefined && retFlow.flow_steps !== undefined && retFlow.flow_steps.length > 0) {

                            const steps = retFlow.flow_steps.map(step => {
                                return {
                                    value: String(step.id_step),
                                    label: step.name,
                                    iconDynamic: step.icon,
                                    color: step.color,
                                    description: step.description,
                                    group: "Etapas do Fluxo",
                                    groupIndex: step.index
                                }
                            });

                            newOptions = steps;

                            const newFields = fieldsConfig.map(field => {
                                if (field.name === 'trigger_flow_step_target_id') {
                                    field.options = newOptions;
                                }
                                return field;
                            });

                            setFieldsConfig(newFields);

                        }
                    }
                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Não foi possível carregar as etapas disponíveis',
                        description: 'Ocorreu um erro ao carregar as etapas disponíveis!',
                    });
                });

            } else if (hasActionNewCard !== undefined) {

                const newFields = fieldsConfig.map(field => {
                    if (field.name === 'action_new_card') {
                        field.flow_id = Number(flowIdSelected);
                    }
                    return field;
                });

                setFieldsConfig(newFields);

            }

            setFlowIdOld(flowIdSelected);

        }

    }, [flowIdSelected, fieldsConfig, flowIdOld, addToast]);

    return (
        <Container>

            <ContainerConfigRightHeader>

                <ContainerCloseButton>
                    <ButtonClose type="button" onClick={handleCloseMenuConfig}>
                        <BsArrowBarRight />
                    </ButtonClose>
                </ContainerCloseButton>

                {type === 'trigger' ?
                    <ContainerConfigRightHeaderTitle>
                        <h3>Configuração</h3>
                        <span>Finalize a configuração do gatilho selecionado</span>
                    </ContainerConfigRightHeaderTitle> : type === 'action' ?
                        <ContainerConfigRightHeaderTitle>
                            <h3>Configuração</h3>
                            <span>Finalize a configuração do gatilho selecionado</span>
                        </ContainerConfigRightHeaderTitle> :
                        <ContainerConfigRightHeaderTitle>
                            <h3>Configuração</h3>
                            <span>Finalize a configuração da condição selecionada</span>
                        </ContainerConfigRightHeaderTitle>
                }

                <ContainerConfigRightHeaderSearch>

                </ContainerConfigRightHeaderSearch>
            </ContainerConfigRightHeader>

            {loading ?
                <ContainerLoader>
                    <Animation className="lottieContainer" ref={lottieContainer} />
                </ContainerLoader > :
                <>
                    {type === 'trigger' ?
                        <>
                            <AutomationConfigGroup>
                                <AutomationConfigItems>
                                    <AutomationConfigItem key={selectedItem.name} color={selectedItem.color}>
                                        <AutomationConfigItemIcon color={selectedItem.color}>
                                            <IconPickerItem
                                                icon={selectedItem.icon as IconList}
                                                color={"white"}
                                            />
                                        </AutomationConfigItemIcon>
                                        <AutomationConfigItemDescription>
                                            <h3>{selectedItem.name}</h3>
                                            <span>{selectedItem.description}</span>
                                        </AutomationConfigItemDescription>
                                    </AutomationConfigItem>
                                </AutomationConfigItems>
                            </AutomationConfigGroup>

                            <DividerMenu />

                            <ContainerForm>
                                {defaultValue !== undefined && (
                                    <FormBuilder
                                        id="form"
                                        formRef={formRef}
                                        fields={fieldsConfig}
                                        initialValue={defaultValue}
                                        flow_id={flow?.id_flow}
                                        hideAutoComplete={true}
                                        handleSubmit={handleSubmitForm}
                                        handleBlur={() => handleSubmitPage()}
                                        hideContainer={true}
                                    />
                                )}
                            </ContainerForm>

                        </> : type === 'action' ?
                            <>
                                <AutomationConfigGroup>
                                    <AutomationConfigItems>
                                        <AutomationConfigItem key={selectedItem.name} color={selectedItem.color}>
                                            <AutomationConfigItemIcon color={selectedItem.color}>
                                                <IconPickerItem
                                                    icon={selectedItem.icon as IconList}
                                                    color={"white"}
                                                />
                                            </AutomationConfigItemIcon>
                                            <AutomationConfigItemDescription>
                                                <h3>{selectedItem.name}</h3>
                                                <span>{selectedItem.description}</span>
                                            </AutomationConfigItemDescription>
                                        </AutomationConfigItem>
                                    </AutomationConfigItems>
                                </AutomationConfigGroup>

                                <DividerMenu />

                                <ContainerFormLabel>
                                    <FormBuilder
                                        id="formLabel"
                                        formRef={formRefLabel}
                                        fields={[LABEL_FIELD]}
                                        initialValue={defaultValueLabel}
                                        hideAutoComplete={true}
                                        handleSubmit={handleSubmitFormLabel}
                                        handleBlur={() => handleSubmitPageLabel()}
                                        hideContainer={true}
                                    />
                                </ContainerFormLabel>

                                <DividerMenu />

                                <ContainerForm>
                                    {defaultValue !== undefined && (
                                        <FormBuilder
                                            id="form"
                                            formRef={formRef}
                                            fields={fieldsConfig}
                                            initialValue={defaultValue}
                                            handleSubmit={handleSubmitForm}
                                            handleBlur={() => handleSubmitPage()}
                                            hideContainer={true}
                                            flow_id={flow?.id_flow}
                                        />
                                    )}
                                </ContainerForm>

                            </> : type === 'condition' ?
                                <>
                                    <AutomationConfigGroup>
                                        <AutomationConfigItems>
                                            <AutomationConfigItem key={selectedItem.name} color={selectedItem.color}>
                                                <AutomationConfigItemIcon color={selectedItem.color}>
                                                    <IconPickerItem
                                                        icon={selectedItem.icon as IconList}
                                                        color={"white"}
                                                    />
                                                </AutomationConfigItemIcon>
                                                <AutomationConfigItemDescription>
                                                    <h3>{selectedItem.name}</h3>
                                                    <span>{selectedItem.description}</span>
                                                </AutomationConfigItemDescription>
                                            </AutomationConfigItem>
                                        </AutomationConfigItems>
                                    </AutomationConfigGroup>

                                    <DividerMenu />

                                    <ContainerFormLabel>
                                        <FormBuilder
                                            id="formLabel"
                                            formRef={formRefLabel}
                                            fields={[LABEL_FIELD]}
                                            initialValue={defaultValueLabel}
                                            hideAutoComplete={true}
                                            handleSubmit={handleSubmitFormLabel}
                                            handleBlur={() => handleSubmitPageLabel()}
                                            hideContainer={true}
                                        />
                                    </ContainerFormLabel>

                                    <DividerMenu />

                                    <ContainerForm>

                                        <FilterComboContainer>
                                            <FilterComboContainerTitle>
                                                Condicionais
                                            </FilterComboContainerTitle>
                                            <FilterComboContainerDescription>
                                                Adicione uma condição para checar se a regra é válida ou não
                                            </FilterComboContainerDescription>

                                            <FilterComboContainerButton
                                                active={filterSchema !== undefined && filterSchema !== null && filterSchema !== "" ? true : false}
                                                onClick={() => {
                                                    setOpenConditionBox(!openConditionBox);
                                                }}
                                            >
                                                {filterSchema !== undefined && filterSchema !== null && filterSchema !== "" ? <MdOutlineFilterList /> : <FaPlus />}
                                                {filterSchema !== undefined && filterSchema !== null && filterSchema !== "" ? filterStateMessage : "Criar novo filtro condicional..."}
                                            </FilterComboContainerButton>
                                        </FilterComboContainer>

                                        {openConditionBox && (
                                            <ConditionBox
                                                open={openConditionBox}
                                                setOpen={setOpenConditionBox}
                                                filterSchema={filterSchema}
                                                setFilterSchema={setFilterSchema}
                                                filterStateMessage={filterStateMessage}
                                                setFilterStateMessage={setFilterStateMessage}
                                                flow={flow}
                                            />
                                        )}

                                    </ContainerForm>
                                </> :
                                <></>
                    }

                </>
            }

        </Container>
    );

}

export default ConfigMenu;