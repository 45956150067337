import { Company } from "../../interfaces/Company";
import { Plan } from "../../interfaces/Plan";

export interface FeatureControlCompany {
    id_feature: number;
    name: string;
    companies: number[];
}

export interface FeatureControlPlan {
    id_feature: number;
    name: string;
    plans: string[];
}

export const AccessPlan: FeatureControlPlan[] = [
    { id_feature: 2, name: "Exportar os dados", plans: ['Plus', 'Professional', "Enterprise"] },
    { id_feature: 3, name: "Copiar o objeto da API", plans: ['Plus', 'Professional', "Enterprise"] },
    { id_feature: 4, name: "Criar uma novo token pessoal", plans: ['Plus', 'Professional', "Enterprise"] },
    { id_feature: 5, name: "Identidade visual personalizada (Whitelabel)", plans: ["Enterprise"] },
    { id_feature: 6, name: "Time Tracking", plans: ['Plus', 'Professional', "Enterprise", "Trial"] },
];

export const AccessCompany: FeatureControlCompany[] = [
    { id_feature: 1, name: "Automations", companies: [67, 215, 415, 433, 535, 786] },
];

export function getFeatureControlByPlan(
    id: number,
    company?: Company
): boolean {

    const accessItem = AccessPlan.find((access) => access.id_feature === id);
    let plan: Plan | undefined = undefined;

    if (company !== undefined && company.company_plan !== undefined) {
        if (company.company_plan.length > 0) {
            plan = company.company_plan[0].plan;
        } else {
            return false;
        }
    } else {
        return false;
    }

    if (accessItem !== undefined && plan !== undefined) {
        if (accessItem.plans.includes(plan.name)) {
            return true;
        }
    }

    return false;
}


export function getFeatureControlByCompany(
    id: number,
    company_id: number
): boolean {

    const accessItem = AccessCompany.find((access) => access.id_feature === id);

    if (accessItem !== undefined) {
        if (accessItem.companies.includes(company_id)) {
            return true;
        }
    }

    return false;
}
