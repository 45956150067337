import {
    AddDueDateCompContainer,
    AddTagContainer,
    Animation,
    ArchivedContainer,
    BtnCompleteCard,
    ButtonAction,
    ButtonActionActionOff,
    ButtonActionAddDueDate,
    ButtonActionAddTag,
    ButtonActionBack,
    ButtonEditSteps,
    ContainerCenter,
    FlowDetailTag,
    ContainerFormCurrent,
    ContainerFormInit,
    ContainerLeft,
    ContainerLeftContent,
    ContainerLoader,
    ContainerRight,
    ContainerRightBottom,
    ContainerRightTitle,
    ContainerRightTop,
    Content,
    ContentBody,
    CurrentStepContainer,
    CurrentStepName,
    DialogContainer,
    DialogTop,
    DialogTopCenter,
    DialogTopItemContainer,
    DialogTopItemContainerUser,
    DialogTopItemValue,
    DialogTopLeft,
    DialogTopRight,
    IconHeader,
    Separator,
    SeparatorTop,
    StepContainerItem,
    StepContainerItemHeader,
    StepContainerItemHeaderDescription,
    StepContainerItemHeaderLeft,
    StepContainerItemMadeBy,
    StepContainerItemTitle,
    TagHeader,
    TaskTag,
    TitleHeader,
    FlowDetailContainer,
    FlowDetailGoTo,
    CurrentStepHeaderContainer,
    TitleHeaderMobile,
    CardCloseBtnMobile,
    DetailsContainerMobile,
    DetailsItemMobile,
    SubHeaderRight,
    ButtonGroupLeft,
    ButtonGroupRight,
    ButtonGroupCenter,
    ContainerCenterMobile,
    MoveCardButtonMobile,
    TimeTrackingContainer,
    CopyBtn,
    ButtonActionFast,
    ContainerButtonAction,
    BtnCloseCard,
    DropDownMenuContainer,
    DropDownShareMenuContainer,
    ContainerCenterFooter,
    ContainerCenterForm,
    FollowCardContainer,
    FollowCardButtonRight,
    FollowButton,
    FollowCardButtonLeft,
    FollowCardDesc,
    DropDownInfo,
    AutomaticData
} from "./styles";
import { AiOutlineClose, AiOutlineInfoCircle } from "react-icons/ai";
import { FaBell, FaBellSlash, FaCalendar, FaCheck, FaCopy, FaPlus, FaRegCalendarAlt, FaTag, FaUser } from "react-icons/fa";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { TbArrowBarRight, TbCheck, TbCheckbox, TbCirclesRelation, TbCopy, TbHistory, TbMail } from "react-icons/tb";

import AddDueDate from "../AddDueDate";
import AddLabel from "../AddLabel";
import AppBarHeaderPocket from "../../components/AppBarHeaderPocket";
import { Attachment } from "../../interfaces/Attachment";
import Attachments from "../../components/Colaboration/Attachments";
import { BsFillArchiveFill, BsGearFill, BsToggleOff, BsToggleOn } from "react-icons/bs";
import ButtonUserAssigned from "../../components/ButtonUserAssigned";
import { CardComment } from "../../interfaces/CardComment";
import { Card as CardInterface } from "../../interfaces/Card";
import { CardItem } from "../../interfaces/CardItem";
import CardMovementLine from "../../components/CardMovementLine";
import CommentBox from "../../components/Colaboration/Comment";
import ConfigSteps from "../ConfigSteps";
import DialogAction from "../DialogAction";
import ContentLoader from "react-content-loader"
import DialogContent from "@material-ui/core/DialogContent";
import EmptyContainer from "../../components/EmptyContainer";
import { FiTrash2 } from "react-icons/fi";
import { FieldProps } from "../../components/Forms/Fields/FieldBuilder";
import { Flow } from "../../interfaces/Flow";
import { FormAnswer } from "../../interfaces/FormAnswer";
import { FormAnswerApi } from "../../interfaces/FormAnswerApi";
import { FormAnswerField } from "../../interfaces/FormAnswerField";
import { FormAnswerFieldApi } from "../../interfaces/FormAnswerFieldApi";
import FormAnswerRecord from "../../components/FormAnswerRecord";
import FormBuilder from "../../components/Forms/FormBuilder";
import { FormHandles } from "@unform/core";
import History from "../../components/Colaboration/History";
import { IoMdLink, IoMdListBox } from "react-icons/io";
import { KanbanConfigProps } from "../../components/Kanban";
import { Link } from "react-router-dom";
import MailTab from "./MailTab";
import { NavigationBarItemType } from "../../components/AppBarHeader";
import RelationshipTab from "./RelationshipTab";
import { Step } from "../../interfaces/Step";
import Title from "../../components/Title";
import UploadButton from "../../components/UploadButton";
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import filesize from "filesize";
import formAnswerToObjectFormInit from "../../utils/formAnswerToObjectFormInit";
import getAccessControl from "../../middlewares/AccessControl";
import getCardItems from "../../middlewares/getCardItems";
import lottie from 'lottie-web';
import { uniqueId } from "lodash";
import { useRouteMatch } from 'react-router-dom';
import { useToast } from '../../hooks/toast';
import CheckListTab from "./CheckListTab";
import getAutoCompleteRule from "../../utils/getAutoCompleteRule";
import { useAuth } from "../../hooks/auth";
import TimeTracking from "../../components/TimeTracking";
import AvatarCange from "../../components/AvatarCange";
import { URL_BASE, URL_FORM_PUBLIC } from "../../config/app";
import { TiFlowChildren } from "react-icons/ti";
import getCheckConditionalStep from "../../components/Forms/FormBuilder/getCheckConditionalStep";
import { HiDotsHorizontal } from "react-icons/hi";
import DropDownList from "../../components/DropDownList";
import DropDownItem from "../../components/DropDownList/DropDownItem";
import DropDownDivider from "../../components/DropDownList/DropDownDivider";
import FormPublicConfig from "../FormPublicConfig";
import { IoShareOutline } from "react-icons/io5";
import { MdCloudDownload } from "react-icons/md";
import AvatarListCange from "../../components/AvatarListCange";
import { CardUser } from "../../interfaces/CardUser";
import { styled, Tooltip, TooltipProps } from '@material-ui/core';

export interface ConfigCardProps {
    open: boolean;
    isMobile: boolean;
    flow: Flow;
    typeUser: string;
    kanban_config?: KanbanConfigProps;
    selectedValue?: CardInterface;
    testModel?: boolean;
    onClose: (updateData: boolean, id_card?: number, keepCardOpen?: boolean) => void;
    showFlowDetail?: boolean;
}

interface RouteParams {
    hash: string;
    origin?: string;
    id?: string;
}

interface objectInit {
    [x: string]: string | object
}

const LoaderAttach = () => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={'50px'}
        viewBox="0 0 700 60"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="60" />
    </ContentLoader>
)

const LoaderStep = () => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={'35px'}
        viewBox="0 0 280 45"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="45" />
    </ContentLoader>
)

const LoaderHistory = () => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={'300px'}
        viewBox="0 0 650 300"
        backgroundColor="#ffffff"
        foregroundColor="#f3f3f3"
    >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="300" />
    </ContentLoader>
)

const LoaderComment = () => (
    <ContentLoader
        speed={2}
        width={'100%'}
        height={'100px'}
        viewBox="0 0 650 100"
        backgroundColor="#ffffff"
        foregroundColor="#f3f3f3"
    >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="100" />
    </ContentLoader>
)

function generateIdFromName(name: string) {
    return name.trim().toLowerCase().replace(/\s+/g, '_');
}

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    "& .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom": {
        backgroundColor: "#1F2D32",
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}));

const Card: React.FC<ConfigCardProps> = ({ onClose, open, isMobile, flow, selectedValue, kanban_config, testModel, typeUser, showFlowDetail }) => {

    const lottieContainer = useRef<HTMLDivElement>(null);
    const { params } = useRouteMatch<RouteParams>();
    const { user } = useAuth();
    const formRef = useRef<FormHandles>(null);
    const { addToast } = useToast();
    const history = useHistory();
    const [step, setStep] = useState<Step>();
    const [fields, setFields] = useState<FieldProps[]>();
    const [newStep, setNewStep] = useState<Step>();
    const [keepCardOpen, setKeepCardOpen] = useState<boolean>();

    const [nextSteps, setNextSteps] = useState<Step[]>();
    const [previousSteps, setPreviousSteps] = useState<Step[]>();
    const [baseSteps, setBaseSteps] = useState<Step[]>();

    const [card, setCard] = useState<CardInterface>();
    const [comments, setComments] = useState<CardComment[]>([]);
    const [preAnswer, setPreAnswer] = useState<object>();
    const [lastObjectBlur, setLastObjectBlur] = useState<FormAnswerFieldApi[]>();
    const [loadingPreAnswer, setLoadingPreAnswer] = useState<boolean>();
    const [openDelCard, setOpenDelCard] = useState(false);

    const [loadingInsert, setLoadingInsert] = useState<boolean>(false);
    const [loadingOpen, setLoadingOpen] = useState<boolean>(false);
    const [loadingComments, setLoadingComments] = useState<boolean>(false);
    const [loadingDelCard, setLoadingDelCard] = useState<boolean>(false);

    //Dialogs control
    const [openConfigStep, setOpenConfigStep] = useState(false);
    const [openAddLabel, setOpenAddLabel] = useState(false);
    const [openAddDueDate, setOpenAddDueDate] = useState(false);

    const [uploadedFiles, setUploadedFiles] = useState<Attachment[]>([]);
    const [loadingAttach, setLoadingAttach] = React.useState<boolean>(false);
    const [loadingSteps, setLoadingSteps] = React.useState<boolean>(false);

    const [relationshipItems, setRelationshipItems] = useState<FormAnswerField[]>();

    const [navBarLeftSelected, setNavBarLeftSelected] = useState(1);

    const [mobileTab, setMobileTab] = useState<number>(0); // 0 - History, 1 - Current Step, 2 - Colaboration
    const [buttonMoveMobile, setButtonMoveMobile] = useState<boolean>(false);
    const [copiedLink, setCopiedLink] = useState<boolean>(false);

    const [openFormPublicConfig, setOpenFormPublicConfig] = useState(false);
    const [openDisclaimerFormPublic, setOpenDisclaimerFormPublic] = useState(false);
    const [loadingCopyLinkPublic, setLoadingCopyLinkPublic] = useState<boolean>(false);

    const [commentOpen, setCommentOpen] = useState<boolean>(false);
    const [isFollower, setIsFollower] = useState<boolean>(false);
    const [followers, setFollowers] = useState<CardUser[]>([]);

    const navBarItemsLeft: NavigationBarItemType[] = [
        {
            index: 1,
            description: "Histórico",
            icon: TbHistory,
            active: navBarLeftSelected === 1 ? true : false,
            onClick: () => setNavBarLeftSelected(1)
        },
        {
            index: 2,
            description: "Conexões",
            icon: TbCirclesRelation,
            active: navBarLeftSelected === 2 ? true : false,
            onClick: () => setNavBarLeftSelected(2),
            badgeItems: relationshipItems !== undefined ? relationshipItems.length : undefined,
            hideItem: relationshipItems !== undefined && relationshipItems.length > 0 ? false : true
        },
        {
            index: 4,
            description: "Checklists",
            icon: TbCheckbox,
            typeUserAllowed: ["A", "M", "R", "I", "V"],
            active: navBarLeftSelected === 4 ? true : false,
            onClick: () => setNavBarLeftSelected(4),
            badgeItems: card?.totCheckListsPending !== undefined ? card?.totCheckListsPending : undefined
        },
        {
            index: 3,
            description: "Email",
            icon: TbMail,
            typeUserAllowed: ["A", "M", "R", "I", "V"],
            active: navBarLeftSelected === 3 ? true : false,
            onClick: () => setNavBarLeftSelected(3),
            badgeItems: card?.totEmailNotRead !== undefined ? card?.totEmailNotRead : undefined
        }
    ]

    const handleCopyClick = () => {
        navigator.clipboard.writeText(URL_BASE + "/flow/" + flow.hash + "/card/" + card?.id_card);

        setCopiedLink(true);
    };

    const updateFile = useCallback((id: any, data: any) => {
        setUploadedFiles((state) =>
            state.map((file) => (file.id_attachment === id ? { ...file, ...data } : file))
        );
    }, []);

    const handleDeleteAttachment = useCallback(async (attach: Attachment) => {

        if (card !== undefined) {

            await api.delete('/attachment', {
                params: {
                    id_attachment: attach.id_attachment,
                    card_id: card.id_card,
                    flow_id: card.flow_id
                }
            }).then(response => {

                //Delete the object from the uploadedFiles
                const newArr = uploadedFiles.filter((file) => file.id_attachment !== attach.id_attachment);

                setUploadedFiles(newArr);


            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao deletar o anexo',
                    description: 'Ocorreu um erro ao tentar deletar o anexo!',
                });
            });
        } else {
            addToast({
                type: 'error',
                title: 'Erro ao deletar o anexo',
                description: 'Não foi possível encontrar nenhuma referência!',
            });
        }

    }, [card, addToast, uploadedFiles]);

    const processUpload = useCallback((uploadedFile: Attachment) => {

        const data = new FormData();

        if (uploadedFile.file !== undefined) {

            data.append('file', uploadedFile.file, uploadedFile.original_name);

            api.post('/attachment', data, {
                onUploadProgress: (event) => {
                    let progress: number = Math.round((event.loaded * 100) / event.total);

                    updateFile(uploadedFile.id_attachment, { progress });

                }
            }).then(response => {

                const retApi: Attachment = response.data

                if (retApi.id_attachment !== undefined) {

                    //Create the relationship with card
                    api.post('/attachment/card', {
                        attachment_id: retApi.id_attachment,
                        card_id: card?.id_card,
                        flow_id: card?.flow_id
                    }).then(response => {

                        //Update the control variables
                        retApi.uploaded = true;
                        if (retApi.blob_size !== undefined) {
                            retApi.readableSize = filesize(retApi.blob_size * 1024);
                        }

                        updateFile(uploadedFile.id_attachment, retApi);

                    }).catch(error => {

                        updateFile(uploadedFile.id_attachment, { error: true });

                    });
                }

            }).catch(error => {

                updateFile(uploadedFile.id_attachment, { error: true });

            });

        }

    }, [updateFile, card]);

    const updateComments = useCallback(async () => {

        if (selectedValue?.id_card !== undefined && selectedValue.flow_id !== undefined) {

            setLoadingComments(true);

            api.get(`/card-comment/by-card`, {
                params: {
                    card_id: selectedValue?.id_card,
                    flow_id: selectedValue.flow_id
                }
            }).then(response => {

                const commentApi: CardComment[] = response.data;

                setComments(commentApi);
                setLoadingComments(false);

            }).catch(error => {
                setLoadingComments(false);
                addToast({
                    type: 'error',
                    title: 'Erro ao carregar os comentários',
                    description: 'Ocorreu um erro ao carregar os comentários!',
                });
            });

        }

    }, [addToast, selectedValue]);

    const onDeleteComment = useCallback(async (id_card_comment?: number) => {

        if (id_card_comment !== undefined && flow.id_flow !== undefined) {

            await api
                .delete('/card-comment', {
                    params: {
                        id_card_comment,
                        flow_id: flow.id_flow
                    }
                })
                .then(response => {

                    updateComments();

                }).catch(error => {
                    console.log(error);
                    addToast({
                        type: 'error',
                        title: 'Erro ao deletar o comentário',
                        description: 'Ocorreu um erro ao deletar o comentário!',
                    });
                });

        }

    }, [addToast, flow.id_flow, updateComments]);

    const onFixComment = useCallback(async (cardComment?: CardComment) => {

        if (card?.id_card !== undefined && flow.id_flow !== undefined && cardComment !== undefined) {

            //fix comment api
            const newObjApi: CardComment = {
                id_card_comment: cardComment.id_card_comment,
                card_id: card?.id_card,
                flow_id: flow.id_flow,
                description: cardComment.description,
                fixed: cardComment.fixed === "1" ? "0" : "1"
            }

            await api
                .post('/card-comment', newObjApi)
                .then(response => {

                    updateComments();

                }).catch(error => {
                    console.log(error);
                    addToast({
                        type: 'error',
                        title: 'Erro ao fixar o comentário',
                        description: 'Ocorreu um erro ao fixar o comentário!',
                    });
                });

        }

    }, [addToast, card, flow.id_flow, updateComments]);

    const handleUploadFile = useCallback((files: File[]) => {

        const uploadedFilesNew: Attachment[] = files.map(file => ({
            file,
            id_attachment: uniqueId(),
            original_name: file.name,
            readableSize: filesize(file.size * 1024),
            progress: 0,
            uploaded: false,
            error: false,
            url: undefined
        }))

        setUploadedFiles(uploadedFiles.concat(uploadedFilesNew));

        uploadedFilesNew.forEach(processUpload);

    }, [uploadedFiles, processUpload]);

    const updateObject = useCallback(async () => {

        if (selectedValue !== undefined && flow !== undefined) {

            setLoadingOpen(true);

            await api.get(`/card`, {
                params: {
                    id_card: selectedValue?.id_card,
                    flow_id: flow.id_flow,
                    company_id: flow.company_id,
                    isTestMode: testModel !== undefined ? testModel : undefined
                }
            }).then(response => {
                if (response.data !== null) {

                    const respCard: CardInterface = response.data;

                    if (respCard !== undefined) {
                        setCard(respCard);

                        //Valid card_users to check if the user is a follower
                        if (respCard.card_users !== undefined) {
                            const userFollower = respCard.card_users.filter((u) => u.user_id === user.id_user && u.isFollower === "S");

                            setIsFollower(userFollower.length > 0 ? true : false);
                            setFollowers(respCard.card_users);
                        }
                    }

                }
                setLoadingOpen(false);
            }).catch(error => {
                setLoadingOpen(false);
                addToast({
                    type: 'error',
                    title: 'Erro ao atualizar o objeto',
                    description: 'Ocorreu um erro ao tentar atualizar o objeto!',
                });
            });

        }

    }, [addToast, selectedValue, flow, testModel, user.id_user]);

    const handleCompleteCard = useCallback(async () => {

        await api
            .put('/card', {
                flow_id: flow.id_flow,
                id_card: card?.id_card,
                complete: card?.complete === "S" ? "N" : "S"
            })
            .then(response => {

                onClose(true, card?.id_card);

                updateObject();

            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao concluir o cartão',
                    description: 'Ocorreu um erro ao concluir o cartão!',
                });
            });


    }, [addToast, card, flow.id_flow, onClose, updateObject]);

    const addDueDateClose = useCallback(async () => {
        updateObject();
        setOpenAddDueDate(false);
    }, [updateObject]);

    const addDueDateOpen = useCallback(async () => {
        setOpenAddDueDate(true);
    }, []);

    const addLabelClose = useCallback(async () => {
        updateObject();
        setOpenAddLabel(false);
    }, [updateObject]);

    const addLabelOpen = useCallback(async () => {
        setOpenAddLabel(true);
    }, []);

    const getApiNextSteps = useCallback(async (card: CardInterface, preAnswer?: object) => {

        if (card !== undefined) {
            if (card.flow_step_id !== undefined && card.flow_id !== undefined) {

                setLoadingSteps(true);

                await api.get(`/flow-step/relationship`, {
                    params: {
                        id_step: card?.flow_step_id,
                        flow_id: card?.flow_id
                    }
                }).then(response => {
                    if (response.data !== null) {

                        let respStep: Step[] = response.data;

                        if (respStep !== undefined) {

                            setBaseSteps(respStep);

                            for (let index = 0; index < respStep.length; index++) {
                                const s = respStep[index];

                                if (s.conditionals !== undefined && s.conditionals !== null && s.conditionals.length > 0) { //Has any conditional

                                    //Check the conditional
                                    respStep[index] = getCheckConditionalStep(s, preAnswer, undefined, card);

                                }

                            }

                            const restStepCurrent: Step[] = respStep.filter((step) => step.id_step === card?.flow_step_id);
                            if (restStepCurrent.length > 0) {

                                //Logica para separa os steps proximos e anteriores
                                const aNextStep = respStep.filter((step) => step.index > restStepCurrent[0].index && step.step_relationship?.isActive === "1" && (step.isAllowedMoviment === undefined || step.isAllowedMoviment === true));
                                const aPreviousStep = respStep.filter((step) => step.index < restStepCurrent[0].index && step.step_relationship?.isActive === "1" && (step.isAllowedMoviment === undefined || step.isAllowedMoviment === true));

                                setNextSteps(aNextStep);
                                setPreviousSteps(aPreviousStep);

                            }

                        }

                    }

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao buscar as Etapas',
                        description: 'Ocorreu ao tentar abrir as Etapas!',
                    });

                });

            }
        }

        setLoadingSteps(false);

    }, [addToast]);

    const configStepsClose = useCallback(async () => {
        setOpenConfigStep(false);

        if (card !== undefined) {
            getApiNextSteps(card, preAnswer);
        }
    }, [getApiNextSteps, card, preAnswer]);

    const configCardOpen = useCallback(async () => {
        setOpenConfigStep(true);
    }, []);

    const deleteCard = useCallback(async (deleted: string) => {

        setLoadingDelCard(true);
        await api
            .put('/card', {
                flow_id: flow.id_flow,
                id_card: card?.id_card,
                deleted: deleted
            })
            .then(response => {

                onClose(true, card?.id_card);

                addToast({
                    type: 'success',
                    title: 'Cartão excluído com sucesso!',
                    description: 'Congrats! Você excluiu o cartão!',
                });
                setLoadingDelCard(false);
            }).catch(error => {
                setLoadingDelCard(false);
                addToast({
                    type: 'error',
                    title: 'Erro ao excluir o cartão',
                    description: 'Ocorreu um erro ao excluir o cartão do fluxo!',
                });
            });

    }, [addToast, flow.id_flow, onClose, card]);

    const dialogDelCardSubmmit = useCallback(async () => {

        await deleteCard("S");

    }, [deleteCard]);

    const archiveCard = useCallback(async (isArchived: string) => {

        await api
            .put('/card', {
                flow_id: flow.id_flow,
                id_card: card?.id_card,
                archived: isArchived
            })
            .then(response => {

                if (isArchived === "S") {
                    addToast({
                        type: 'success',
                        title: 'Cartão arquivado com sucesso!',
                        description: 'Congrats! Você arquivou o card!',
                    });
                } else {
                    addToast({
                        type: 'success',
                        title: 'Cartão desarquivado com sucesso!',
                        description: 'Congrats! Você desarquivou o card! :)',
                    });
                }

                updateObject();

            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao mover o cartão',
                    description: 'Ocorreu um erro ao mover o cartão de etapa!',
                });
            });

    }, [addToast, flow.id_flow, card, updateObject]);

    const moveCard = useCallback(async (newStep: Step, isActiveAssignments?: boolean, keepCardOpen?: boolean) => {

        if (step !== undefined) {

            await api
                .post('/card/move-step', {
                    flow_id: flow.id_flow,
                    id_card: card?.id_card,
                    to_step_id: newStep.id_step,
                    from_step_id: step.id_step,
                    complete: newStep.isEndStep === "1" ? "S" : "N",
                    isActiveAssignments: isActiveAssignments
                })
                .then(response => {

                    addToast({
                        type: 'success',
                        title: 'Cartão movido com sucesso!',
                        description: 'Congrats! Você moveu o cartão para a etapa ' + step.name + ' :)',
                    });

                    setNewStep(undefined);
                    setKeepCardOpen(false);
                    setLoadingInsert(false);

                    if (!keepCardOpen) {
                        onClose(true, card?.id_card);
                    } else {
                        onClose(true, card?.id_card, keepCardOpen); //keep
                    }

                }).catch(error => {
                    setLoadingInsert(false);
                    addToast({
                        type: 'error',
                        title: 'Erro ao mover o cartão',
                        description: error.response.data.message
                    });
                });

        } else {
            setLoadingInsert(false);
        }

    }, [addToast, flow.id_flow, onClose, card, step]);

    const handleMoveCard = useCallback(async (stepNew: Step, keepCardOpen?: boolean) => {

        await setNewStep(stepNew);
        await setKeepCardOpen(keepCardOpen);

        if (formRef.current !== null) {
            formRef.current.submitForm();
        } else {
            moveCard(stepNew, true, keepCardOpen);
        }

    }, [moveCard]);

    const updateSteps = useCallback((currData: object[]) => {

        let newSteps = baseSteps;

        if (newSteps !== undefined && newSteps.length > 0) { //Has any previous step

            for (let index = 0; index < newSteps.length; index++) {
                const s = newSteps[index];

                if (s.conditionals !== undefined && s.conditionals !== null && s.conditionals.length > 0) { //Has any conditional

                    //Check the conditional
                    newSteps[index] = getCheckConditionalStep(s, preAnswer, currData, card);

                }

            }

            //Update the previus and next steps
            const restStepCurrent: Step[] = newSteps.filter((step) => step.id_step === card?.flow_step_id);
            if (restStepCurrent.length > 0) {

                //Logica para separa os steps proximos e anteriores
                const aNextStep = newSteps.filter((step) => step.index > restStepCurrent[0].index && step.step_relationship?.isActive === "1" && (step.isAllowedMoviment === undefined || step.isAllowedMoviment === true));
                const aPreviousStep = newSteps.filter((step) => step.index < restStepCurrent[0].index && step.step_relationship?.isActive === "1" && (step.isAllowedMoviment === undefined || step.isAllowedMoviment === true));

                setNextSteps(aNextStep);
                setPreviousSteps(aPreviousStep);

            }

        }

    }, [card, preAnswer, baseSteps]);

    const handleBlur = useCallback(async () => {

        if (!loadingPreAnswer && getAccessControl(50, typeUser)) {
            setLoadingPreAnswer(true);
            //Get the actual data from form
            if (formRef.current !== null) {
                const currData: object[] = formRef.current.getData() as object[];
                const dataNormalized = currData as unknown as FormAnswerFieldApi[];

                updateSteps(currData);

                //Valid if changed the object
                if (JSON.stringify(lastObjectBlur) !== JSON.stringify(dataNormalized)) {
                    if (step !== undefined &&
                        step.form_id !== undefined) {

                        const objApi: FormAnswerApi = {
                            id_form: step?.form_id,
                            origin: "/Flow/Card-Pre",
                            values: dataNormalized,
                            flow_id: flow.id_flow,
                            card_id: card?.id_card,
                            isTestMode: testModel
                        }

                        await api
                            .post('/form/pre-new-answer', objApi)
                            .then(response => {
                                setLastObjectBlur(dataNormalized);
                            }).catch(error => {
                                addToast({
                                    type: 'error',
                                    title: 'Erro ao salvar o formulário da etapa atual',
                                    description: 'Ocorreu um erro ao efetuar o salvamento do formulário da etapa atual!',
                                });
                            });
                    }
                }
            }
            setLoadingPreAnswer(false);
        }

    }, [addToast, card?.id_card, flow.id_flow, testModel, step, lastObjectBlur, loadingPreAnswer, typeUser, updateSteps]);

    const handleSubmit = useCallback(async (data: object[]) => {

        const dataNormalized = data as unknown as FormAnswerFieldApi[];

        if (step !== undefined &&
            step.form_id !== undefined &&
            newStep !== undefined) {

            const objApi: FormAnswerApi = {
                id_form: step?.form_id,
                origin: "/Flow/Card",
                values: dataNormalized,
                flow_step_id: step.id_step,
                flow_id: flow.id_flow,
                card_id: card?.id_card,
                isTestMode: testModel,
                moveToStep: newStep.id_step
            }

            setLoadingInsert(true);
            await api
                .post('/form/new-answer', objApi)
                .then(response => {

                    moveCard(newStep, undefined, keepCardOpen);

                }).catch(error => {
                    setLoadingInsert(false);
                    console.log(error.response.data);
                    addToast({
                        type: 'error',
                        title: 'Erro ao mover o cartão :(',
                        description: error.response.data.message,
                    });
                });


        } else {
            addToast({
                type: 'error',
                title: 'Erro ao mover o cartão[2]',
                description: 'Ocorreu um erro ao mover o registro!',
            });
        }

    }, [step, addToast, flow.id_flow, newStep, card, moveCard, testModel, keepCardOpen]);

    const handleCopyCard = useCallback(async (id_card: number) => {

        await api
            .post('/card/copy', {
                flow_id: flow.id_flow,
                id_card: id_card
            })
            .then(response => {

                if (response.data !== null) {
                    const newCard: CardInterface = response.data;
                    onClose(true, newCard.id_card);
                }

            }).catch(error => {
                setLoadingInsert(false);
                console.log(error.response.data);
                addToast({
                    type: 'error',
                    title: 'Erro ao mover o cartão :(',
                    description: error.response.data.message,
                });
            });

    }, [addToast, flow.id_flow, onClose]);

    const handleClose = useCallback(async () => {
        await handleBlur();

        onClose(true, card?.id_card);
    }, [onClose, handleBlur, card?.id_card]);

    const getApiRelationship = useCallback(async () => {

        if (selectedValue !== undefined && selectedValue.id_card !== undefined && selectedValue.flow_id !== undefined) {

            api.get(`/card/relationship`, {
                params: {
                    id_card: selectedValue?.id_card,
                    flow_id: selectedValue?.flow_id
                }
            }).then(response => {
                if (response.data !== null) {

                    let apiRet: FormAnswerField[] = response.data.formsAnswerFields;

                    setRelationshipItems(apiRet);

                }
            });

        }

    }, [selectedValue]);

    const getApiAttachments = useCallback(async () => {

        if (selectedValue !== undefined && selectedValue.id_card !== undefined && selectedValue.flow_id !== undefined) {

            setLoadingAttach(true);

            api.get(`/attachment/by-card`, {
                params: {
                    id_card: selectedValue?.id_card,
                    flow_id: selectedValue?.flow_id
                }
            }).then(response => {
                if (response.data !== null) {

                    let apiRet: Attachment[] = response.data;

                    for (let index = 0; index < apiRet.length; index++) {
                        const attach = apiRet[index];

                        attach.uploaded = true;
                        if (attach.blob_size !== undefined) {
                            attach.readableSize = filesize(attach.blob_size * 1024);
                        }

                        apiRet[index] = attach;

                    }

                    setUploadedFiles(apiRet);

                }
            });

            setLoadingAttach(false);

        }

    }, [selectedValue]);

    const getApiPreAnswer = useCallback(async (card_id?: number, step?: Step) => {

        setLoadingPreAnswer(true);
        if (card_id !== undefined && step !== undefined) {

            try {

                const responsePreAnswer = await api.get(`/form/pre-answer`, {
                    params: {
                        flow_id: flow.id_flow,
                        card_id: card_id,
                        id_form: step.form_id,
                    }
                });

                if (responsePreAnswer !== undefined && responsePreAnswer.data !== null) {

                    let apiRetFormAnswer: FormAnswer = responsePreAnswer.data.formsAnswers;
                    let apiRetFields: FieldProps[] = responsePreAnswer.data.fields;

                    let autoCompleteFA: FormAnswerField[] = [];

                    if (apiRetFields !== undefined) {

                        autoCompleteFA = await getAutoCompleteRule('answer', apiRetFields, user.id_user, card_id, flow.id_flow);
                        setFields(apiRetFields);

                    } else if (step !== undefined && step.form.fields !== undefined) {

                        setFields(step.form.fields);

                    }

                    if (apiRetFormAnswer !== undefined && apiRetFormAnswer.form_answer_fields !== undefined) {

                        const newObjData: objectInit = formAnswerToObjectFormInit(apiRetFormAnswer.form_answer_fields, autoCompleteFA);
                        setPreAnswer(newObjData);

                    } else {

                        const newObjData: objectInit = formAnswerToObjectFormInit(autoCompleteFA);
                        setPreAnswer(newObjData);

                    }

                }

                setStep(step);

            } catch (error) {
                console.log(error);
                setStep(step);
            }
        }
        setLoadingPreAnswer(false);

    }, [flow.id_flow, user.id_user]);

    const handleCopyLinkPublicStep = useCallback(async () => {

        if (card !== undefined && card.id_card !== undefined && flow !== undefined && flow.id_flow !== undefined && step !== undefined && step.form !== undefined && step.form.id_form !== undefined) {

            setLoadingCopyLinkPublic(true);

            await api.post('/card/share-public', {
                card_id: card?.id_card,
                flow_id: flow.id_flow,
                form_id: step?.form?.id_form
            }).then(response => {
                if (response.data !== null) {

                    const newHashObject: { card_public_hash: string, form_public_hash: string } = response.data;

                    if (newHashObject.card_public_hash !== undefined && newHashObject.card_public_hash !== null && newHashObject.form_public_hash !== undefined && newHashObject.form_public_hash !== null) {
                        //Update the card object
                        setCard({
                            ...card,
                            public_hash: newHashObject.card_public_hash
                        });

                        //Update the form object
                        setStep({
                            ...step,
                            form: {
                                ...step.form,
                                public_hash: newHashObject.form_public_hash,
                                public_form: 'S'
                            }
                        });

                        //Copy the link to clipboard
                        navigator.clipboard.writeText(URL_FORM_PUBLIC + "/public/" + newHashObject.form_public_hash + "/" + newHashObject.card_public_hash);

                        addToast({
                            type: 'success',
                            title: 'Link copiado com sucesso!',
                            description: 'O link foi copiado para a área de transferência!',
                        });
                    }
                }
                setLoadingCopyLinkPublic(false);
            }).catch(error => {
                setLoadingCopyLinkPublic(false);
                addToast({
                    type: 'error',
                    title: 'Erro ao compartilhar o cartão',
                    description: 'Ocorreu um erro ao tentar compartilhar o cartão!',
                });
            });

        }

    }, [card, addToast, flow, step]);

    const handleInactiveLinkPublicStep = useCallback(async () => {

        if (card !== undefined && card.id_card !== undefined && flow !== undefined && flow.id_flow !== undefined && step !== undefined && step.form !== undefined && step.form.id_form !== undefined) {

            await api.post('/card/inactive-share-public', {
                card_id: card?.id_card,
                flow_id: flow.id_flow,
                form_id: step?.form?.id_form
            }).then(response => {
                if (response.data !== null) {

                    //Update the card object
                    setCard({
                        ...card,
                        public_hash: undefined
                    });

                    addToast({
                        type: 'success',
                        title: 'Link desativado com sucesso!',
                        description: 'O link foi desativado com sucesso!',
                    });

                }
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao desativar o link',
                    description: 'Ocorreu um erro ao tentar desativar o link!',
                });
            });

        }

    }, [card, addToast, flow, step]);

    const handleDownloadAllFiles = useCallback(async () => {

        if (card !== undefined) {

            api.get(`/attachment/url-download/by-card`, {
                params: {
                    card_id: card.id_card,
                    flow_id: card.flow_id
                }
            }).then(response => {

                if (response.data !== null) {
                    const urlFiles: string[] = response.data;

                    if (urlFiles !== undefined && urlFiles.length > 0) {

                        for (let index = 0; index < urlFiles.length; index++) {
                            const urlFile = urlFiles[index];

                            const link = document.createElement('a');
                            link.href = urlFile;
                            link.setAttribute('download', '');
                            link.setAttribute('target', '_blank');
                            link.setAttribute('rel', 'noopener noreferrer');
                            link.setAttribute('id', 'id' + index);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    } else {
                        addToast({
                            type: 'info',
                            title: 'Este cartão não possui nenhum arquivo para ser feito o download',
                            description: 'Após adicionar arquivos, você poderá fazer o download de todos os arquivos por aqui!',
                        });
                    }
                }

            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao fazer o download do arquivo',
                    description: 'Ocorreu um erro ao fazer o download do arquivo!',
                });
            });

        }

    }, [addToast, card]);

    const handleUpdateFollowers = useCallback(async (follower: CardUser, opt: 'INS' | 'DEL') => {

        if (opt === 'INS') {
            let newArr = followers.filter((f) => f.user_id !== follower.user_id);
            newArr = [...newArr, follower];
            setFollowers(newArr);

            const userFollower = newArr.filter((u) => u.user_id === user.id_user && u.isFollower === "S");
            setIsFollower(userFollower.length > 0 ? true : false);
        } else {
            let newArr = followers.filter((f) => f.user_id !== follower.user_id);
            setFollowers(newArr);

            const userFollower = newArr.filter((u) => u.user_id === user.id_user && u.isFollower === "S");
            setIsFollower(userFollower.length > 0 ? true : false);
        }

    }, [followers, user.id_user]);

    const handleFollowCard = useCallback(async (user_id?: number) => {

        if (user !== undefined) {

            await api
                .post('/card/preferences', {
                    user_id: user_id,
                    card_id: card?.id_card,
                    isFollower: "S",
                    returnUser: "S"
                })
                .then(response => {

                    const retCardUser: CardUser = response.data;

                    if (retCardUser !== undefined) {
                        handleUpdateFollowers(retCardUser, "INS");
                    }

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao seguir o cartão',
                        description: 'Ocorreu um erro ao tentar seguir o cartão!',
                    });
                });

        }

    }, [addToast, card, user, handleUpdateFollowers]);

    const handleUnfollowCard = useCallback(async (user_id?: number) => {

        if (user !== undefined) {

            await api
                .post('/card/preferences', {
                    user_id: user_id,
                    card_id: card?.id_card,
                    isFollower: "N",
                })
                .then(response => {

                    const retCardUser: CardUser = response.data;

                    if (retCardUser !== undefined) {
                        handleUpdateFollowers(retCardUser, "DEL");
                    }

                }).catch(error => {
                    addToast({
                        type: 'error',
                        title: 'Erro ao deixar de seguir o cartão',
                        description: 'Ocorreu um erro ao tentar deixar seguir o cartão!',
                    });
                });

        }

    }, [addToast, card, user, handleUpdateFollowers]);

    useEffect(() => {

        let newCard = selectedValue;

        //Seta o step atual na variável
        if (newCard !== undefined && flow.flow_steps !== undefined) {
            const currentStep = flow.flow_steps.filter((step) => step.id_step === selectedValue?.flow_step_id);

            if (selectedValue !== undefined && currentStep[0].form_id !== undefined) {
                //Get if has any answer already answered
                getApiPreAnswer(selectedValue.id_card, currentStep[0]);
            } else {
                setStep(currentStep[0]);
                setFields(currentStep[0].form.fields);
            }
        }

        //Ajusta todos os formsAnswer para exibir os itens
        if (newCard !== undefined && kanban_config !== undefined && newCard.form_answers !== undefined) {

            for (let index = 0; index < newCard.form_answers.length; index++) {

                let arrFieldsAnswerFormInit = [] as FormAnswerField[];

                newCard.form_answers[index].form_answer_fields.map((form_answer_field) => {
                    arrFieldsAnswerFormInit.push(form_answer_field);
                    return form_answer_field;
                });

                //GetItems
                if (flow.form_init_id === newCard.form_answers[index].form_id) { //Form Init
                    let itemsArrFormInit: CardItem[] = getCardItems(2, arrFieldsAnswerFormInit, kanban_config);
                    if (itemsArrFormInit !== undefined) {
                        newCard.form_answers[index].items = itemsArrFormInit;
                    } else {
                        newCard.form_answers[index].items = [];
                    }
                } else { //Form All
                    let itemsArrFormInit: CardItem[] = getCardItems(3, arrFieldsAnswerFormInit, kanban_config, newCard.form_answers[index].form_id);
                    if (itemsArrFormInit !== undefined) {
                        newCard.form_answers[index].items = itemsArrFormInit;
                    } else {
                        newCard.form_answers[index].items = [];
                    }
                }

            }
        }

        //Seta o objeto na nova variavel
        if (newCard !== undefined) {
            setCard(newCard);
        }

    }, [selectedValue, flow.form_init_id, kanban_config, flow.flow_steps, params, getApiPreAnswer])

    const goToFlow = useCallback((flow: Flow) => {
        history.push('/flow/' + flow.hash);
    }, [history]);

    useEffect(() => {

        if (card !== undefined) {
            getApiNextSteps(card, preAnswer);
        }
    }, [card, getApiNextSteps, preAnswer]);

    useEffect(() => {

        //Get the Comments
        updateComments();

    }, [updateComments]);

    useEffect(() => {

        //Get the Attachments
        getApiAttachments();

    }, [getApiAttachments]);

    useEffect(() => {

        //Get the Relationship
        getApiRelationship();

    }, [getApiRelationship]);

    useEffect(() => {

        updateObject();

    }, [open, updateObject]);

    useEffect(() => {
        if (lottieContainer.current) {
            lottie.loadAnimation({
                container: lottieContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: require('../../assets/lottie/loader-circle-white.json')
            })
        }
    }, [loadingPreAnswer]);

    return (

        !isMobile ?
            <DialogContainer
                fullWidth={true}
                maxWidth="lg"
                open={open}
                onClose={handleClose}
            >
                {showFlowDetail && (
                    <FlowDetailContainer>
                        <FlowDetailTag>
                            Fluxo:
                        </FlowDetailTag>
                        {flow.name}

                        <FlowDetailGoTo onClick={() => goToFlow(flow)}>
                            Ir para o Fluxo
                        </FlowDetailGoTo>
                    </FlowDetailContainer>
                )}
                <DialogContent style={{ height: '100%', padding: '0px' }}>
                    <DialogTop>
                        <DialogTopLeft>
                            {card?.dt_due !== undefined && card?.dt_due !== null && getAccessControl(27, typeUser) ?
                                <DarkTooltip title={
                                    <React.Fragment>
                                        <div>Conclua o cartão nesta etapa</div>
                                    </React.Fragment>
                                }>
                                    <BtnCompleteCard complete={card?.complete} onClick={() => handleCompleteCard()}>
                                        {card?.complete === "S" ?
                                            <>
                                                <FaCheck />
                                            </> :
                                            <>
                                                <FaCheck />
                                            </>
                                        }
                                    </BtnCompleteCard>
                                </DarkTooltip> :
                                <IconHeader color={flow.color}>
                                    <div>
                                        {flow !== undefined && flow.icon !== undefined ?
                                            <IconPickerItem
                                                icon={flow.icon as IconList}
                                                color={flow.color}
                                            /> :
                                            <IoMdListBox />
                                        }
                                    </div>
                                </IconHeader>
                            }
                            <TitleHeader>
                                <h1>
                                    {card?.title}
                                </h1>
                                {card !== undefined && card.card_flow_tags !== undefined && card.card_flow_tags.length > 0 ?
                                    <TagHeader>
                                        {card.card_flow_tags?.map((tag) => {
                                            return (
                                                <TaskTag key={tag.id_card_flow_tag} color={tag.flow_tag.color}>{tag.flow_tag.description}</TaskTag>
                                            );
                                        })}
                                    </TagHeader> :
                                    <></>
                                }
                            </TitleHeader>
                        </DialogTopLeft>

                        <DialogTopCenter>

                            {card?.archived !== undefined && card.archived === "S" ?
                                <DialogTopItemContainer style={{ marginRight: "20px" }}>
                                    <ArchivedContainer color={"gray"}>
                                        <BsFillArchiveFill />
                                        <CurrentStepName>
                                            {"Arquivado"}
                                        </CurrentStepName>
                                    </ArchivedContainer>
                                </DialogTopItemContainer> :
                                <></>
                            }

                            {card?.user !== undefined ?
                                <DialogTopItemContainerUser title={card.user.name}>
                                    <AvatarCange
                                        user={card.user}
                                        size="35"
                                    />
                                </DialogTopItemContainerUser> :
                                <></>
                            }

                            <SeparatorTop />

                            {card?.dt_due_string !== undefined ?
                                <DialogTopItemContainer>
                                    <h3>Data vencimento</h3>
                                    <DialogTopItemValue>
                                        {card?.dt_due_string}
                                    </DialogTopItemValue>
                                </DialogTopItemContainer> :
                                <></>
                            }

                            <SeparatorTop />

                            <DialogTopItemContainer>
                                <h3>Etapa atual</h3>
                                <CurrentStepContainer color={step !== undefined && step.color !== undefined ? step.color : "red"}>
                                    {step !== undefined && step.icon !== undefined ?
                                        <IconPickerItem
                                            icon={step.icon as IconList}
                                            color={"white"}
                                        /> :
                                        <IoMdListBox />
                                    }
                                    <CurrentStepName>
                                        {step !== undefined ? step.name : "..."}
                                    </CurrentStepName>
                                </CurrentStepContainer>
                            </DialogTopItemContainer>
                        </DialogTopCenter>

                        <DialogTopRight>

                            <DropDownMenuContainer>
                                {(getAccessControl(12, typeUser) || getAccessControl(57, typeUser)) && (
                                    <DropDownList color={"#f4f4f4"} icon={HiDotsHorizontal}>

                                        {card !== undefined && card.id_card !== undefined ?
                                            <>
                                                <DropDownInfo style={{ paddingBottom: '0px' }}>
                                                    <span>Identificador:</span> <AutomaticData>{card.id_card}</AutomaticData>
                                                </DropDownInfo>
                                                <DropDownDivider />
                                            </> :
                                            <></>
                                        }

                                        <DropDownItem title="Fazer donwload dos arquivos" icon={MdCloudDownload} color={"#f23b5c"} onClick={() => handleDownloadAllFiles()} />

                                        {getAccessControl(12, typeUser) && card !== undefined && card.id_card !== undefined ?
                                            <DropDownItem title="Copiar este cartão" icon={FaCopy} color={"#f23b5c"} onClick={() => handleCopyCard(card?.id_card)} /> :
                                            <></>
                                        }

                                        {getAccessControl(57, typeUser) && (card?.archived === undefined || card?.archived !== "S") ?
                                            <>
                                                <DropDownDivider />
                                                <DropDownItem title="Arquivar" icon={BsFillArchiveFill} color={"#795548"} onClick={() => archiveCard("S")} />
                                            </> :
                                            <>
                                                <DropDownDivider />
                                                <DropDownItem title="Desarquivar" icon={BsFillArchiveFill} color="#795548" onClick={() => archiveCard("N")} />
                                                <DropDownItem title="Excluir" icon={FiTrash2} color="#f23b5c" onClick={() => setOpenDelCard(true)} />
                                            </>
                                        }

                                    </DropDownList>
                                )}
                            </DropDownMenuContainer>

                            <BtnCloseCard className="btn-card-close" onClick={handleClose}><AiOutlineClose /></BtnCloseCard>
                        </DialogTopRight>
                    </DialogTop>

                    <Content>
                        <ContentBody container>
                            <ContainerLeft
                                style={{ padding: '0px' }}
                                item
                                xs={getAccessControl(25, typeUser) ? 4 : 6}
                                sm={getAccessControl(25, typeUser) ? 4 : 6}
                                md={getAccessControl(25, typeUser) ? 5 : 6}
                                lg={getAccessControl(25, typeUser) ? 5 : 6}>

                                <AppBarHeaderPocket
                                    navBarItems={navBarItemsLeft}
                                    typeUser={typeUser}
                                />


                                <ContainerLeftContent>

                                    {navBarLeftSelected === 1 ?
                                        <div>

                                            {loadingOpen && (card === undefined || card.form_answers === undefined) && (
                                                <div style={{ marginTop: '10px' }}>
                                                    <LoaderHistory />
                                                    <LoaderHistory />
                                                </div>
                                            )}

                                            {card !== undefined && card.card_movement !== undefined ?
                                                <CardMovementLine
                                                    card_movements={card.card_movement}
                                                    flow={card?.flow}
                                                    current_step={step}
                                                /> :
                                                <></>
                                            }

                                            {card !== undefined && card.form_answers !== undefined && card?.form_answers?.filter((answer) => answer.form_answer_fields.length > 0).map((form_answer) => {

                                                const stepCurrent = flow.flow_steps !== undefined ? flow.flow_steps.filter((step) => step.id_step === form_answer.flow_step?.id_step)[0] : undefined;

                                                return (
                                                    <div id="tour-card-hist" key={form_answer.id_form_answer}>
                                                        {form_answer.form !== undefined && form_answer.form.name === "FI" ?
                                                            <div>
                                                                <StepContainerItem className="form-init-container" key={form_answer.id_form_answer} color="#eef2f8"> {/* Formulário Inicial */}
                                                                    <StepContainerItemHeader>
                                                                        <StepContainerItemHeaderLeft color="#4d4d4d">
                                                                            <IoMdListBox />
                                                                        </StepContainerItemHeaderLeft>
                                                                        <StepContainerItemHeaderDescription>
                                                                            <StepContainerItemTitle
                                                                                title="Formulário Inicial"
                                                                                required={false}
                                                                                font_size="18px"
                                                                                font_weight="600"
                                                                            />
                                                                            {form_answer.origin === "/PublicForm" || form_answer.origin === "/PublicForm/Step" ?
                                                                                <StepContainerItemMadeBy>Criado via Formulário Público em {form_answer?.dt_created_string} <AiOutlineInfoCircle title={"#" + form_answer.id_form_answer} style={{ marginLeft: "5px" }} /></StepContainerItemMadeBy> :
                                                                                form_answer.origin === "/Automation/CreateNewCardService" || form_answer.origin === "/Automation/CreateChildCardService" ?
                                                                                    <StepContainerItemMadeBy>Criado via Automações em {form_answer?.dt_created_string} <AiOutlineInfoCircle title={"#" + form_answer.id_form_answer} style={{ marginLeft: "5px" }} /></StepContainerItemMadeBy> :
                                                                                    <StepContainerItemMadeBy>Criado por {form_answer.user_created} em {form_answer?.dt_created_string} <AiOutlineInfoCircle title={"#" + form_answer.id_form_answer} style={{ marginLeft: "5px" }} /></StepContainerItemMadeBy>
                                                                            }
                                                                        </StepContainerItemHeaderDescription>
                                                                    </StepContainerItemHeader>
                                                                    <ContainerFormInit>
                                                                        {flow.form_init !== undefined ?
                                                                            <FormAnswerRecord
                                                                                selectedValue={form_answer}
                                                                                form={flow.form_init}
                                                                                showDetailAnswer={false}
                                                                                hideContainer={true}
                                                                                forceUpdate={updateObject}
                                                                                notUpdateObject={true}
                                                                                typeUser={typeUser}
                                                                                flow_id={flow.id_flow}
                                                                                card_current_id={card?.id_card}
                                                                            /> :
                                                                            <></>}
                                                                    </ContainerFormInit>
                                                                </StepContainerItem>
                                                                {getAccessControl(26, typeUser) ?
                                                                    <Link to={'/flow/2/' + flow.hash + "/edit"} style={{ textDecoration: 'none' }}>
                                                                        <EmptyContainer
                                                                            message="para customizar o formulário inicial"
                                                                            Icon={FaPlus}
                                                                        />
                                                                    </Link> : <></>
                                                                }
                                                            </div> : form_answer.form !== undefined && form_answer.flow_step !== undefined && form_answer.form !== null && form_answer.flow_step !== null ?
                                                                <StepContainerItem key={form_answer.id_form_answer} color="#eef2f8">
                                                                    <StepContainerItemHeader>
                                                                        <StepContainerItemHeaderLeft color={form_answer.flow_step !== undefined && form_answer.flow_step !== null && form_answer.flow_step.color !== undefined && form_answer.flow_step.color !== null ? form_answer.flow_step.color : "#4d4d4d"}>
                                                                            {form_answer.flow_step !== undefined && form_answer.flow_step !== null && form_answer.flow_step.icon !== undefined && form_answer.flow_step.icon !== null ?
                                                                                <IconPickerItem
                                                                                    icon={form_answer.flow_step.icon as IconList}
                                                                                    color={form_answer.flow_step !== undefined && form_answer.flow_step !== null && form_answer.flow_step.color !== undefined && form_answer.flow_step.color !== null ? form_answer.flow_step.color : "#4d4d4d"}
                                                                                /> :
                                                                                <IoMdListBox />
                                                                            }
                                                                        </StepContainerItemHeaderLeft>
                                                                        <StepContainerItemHeaderDescription>
                                                                            <StepContainerItemTitle
                                                                                title={form_answer.flow_step !== undefined && form_answer.flow_step !== null && form_answer.flow_step.name !== undefined && form_answer.flow_step.name !== null ? form_answer.flow_step.name : form_answer.form.name !== undefined && form_answer.form.name !== null ? form_answer.form.name : ""}
                                                                                required={false}
                                                                                font_size="18px"
                                                                                font_weight="600"
                                                                            />
                                                                            {form_answer.origin === "/PublicForm/Step" ?
                                                                                <StepContainerItemMadeBy>Movido via Formulário Público em {form_answer?.dt_created_string} <AiOutlineInfoCircle title={"#" + form_answer.id_form_answer} style={{ marginLeft: "5px" }} /></StepContainerItemMadeBy> :
                                                                                <StepContainerItemMadeBy>Movido por {form_answer.user_created} em {form_answer.dt_created_string} <AiOutlineInfoCircle title={"#" + form_answer.id_form_answer} style={{ marginLeft: "5px" }} /></StepContainerItemMadeBy>
                                                                            }
                                                                        </StepContainerItemHeaderDescription>

                                                                    </StepContainerItemHeader>
                                                                    <ContainerFormInit hide={form_answer.showDetail}>
                                                                        {stepCurrent !== undefined ?
                                                                            <FormAnswerRecord
                                                                                selectedValue={form_answer}
                                                                                form={stepCurrent?.form}
                                                                                showDetailAnswer={false}
                                                                                hideContainer={true}
                                                                                notUpdateObject={true}
                                                                                forceUpdate={updateObject}
                                                                                typeUser={typeUser}
                                                                                flow_id={flow.id_flow}
                                                                                card_current_id={card?.id_card}
                                                                            /> :
                                                                            <></>
                                                                        }
                                                                    </ContainerFormInit>
                                                                </StepContainerItem> :
                                                                <></>
                                                        }
                                                    </div>
                                                );
                                            })}
                                        </div> :
                                        navBarLeftSelected === 2 ?
                                            <RelationshipTab
                                                id_card={selectedValue?.id_card}
                                                flow_id={selectedValue?.flow_id}
                                                formAnswerFields={relationshipItems}
                                            /> : navBarLeftSelected === 3 ?
                                                <MailTab
                                                    typeUser={typeUser}
                                                    flow={flow}
                                                    card_id={selectedValue?.id_card}
                                                /> : navBarLeftSelected === 4 ?
                                                    <CheckListTab
                                                        card_id={selectedValue?.id_card}
                                                        flow_id={selectedValue?.flow_id}
                                                        typeUser={typeUser}
                                                    /> :
                                                    <></>
                                    }

                                </ContainerLeftContent>
                            </ContainerLeft>

                            <ContainerCenter
                                item
                                xs={getAccessControl(25, typeUser) ? 5 : 6}
                                sm={getAccessControl(25, typeUser) ? 5 : 6}
                                md={getAccessControl(25, typeUser) ? 5 : 6}
                                lg={getAccessControl(25, typeUser) ? 5 : 6}
                            >
                                <ContainerCenterForm isOpen={commentOpen}>

                                    {/* Current Step */}
                                    {getAccessControl(25, typeUser) ?
                                        <>
                                            <CurrentStepHeaderContainer>
                                                <Title
                                                    title="Etapa atual"
                                                    required={false}
                                                    font_size="20px"
                                                    font_weight="600"
                                                />

                                                <DropDownShareMenuContainer>
                                                    <DropDownList color={"#9e37ed1e"} icon={IoShareOutline} name="Compartilhar">
                                                        <DropDownItem title={!copiedLink ? "Copiar o link do cartão" : "O link do cartão foi copiado"} icon={!copiedLink ? TbCopy : TbCheck} color={!copiedLink ? "#9e37ed" : "#61bd4f"} onClick={handleCopyClick} />

                                                        {step !== undefined && step.form.fields !== undefined && step.form.fields.length > 0 ?
                                                            <>
                                                                <DropDownDivider />
                                                                {card !== undefined && card.public_hash !== undefined && card.public_hash !== null && step !== undefined && step.form.public_hash !== undefined && step.form.public_hash !== null && step.form.public_form === 'S' ?
                                                                    <DropDownItem title="Copiar link público da etapa" icon={IoMdLink} color={"#4681b8"} onClick={() => handleCopyLinkPublicStep()} /> :
                                                                    <DropDownItem title="Ativar link público da etapa" icon={BsToggleOff} color={"#4681b8"} onClick={() => setOpenDisclaimerFormPublic(true)} />
                                                                }
                                                                {card !== undefined && card.public_hash !== undefined && card.public_hash !== null && step !== undefined && step.form.public_hash !== undefined && step.form.public_hash !== null && step.form.public_form === 'S' && (
                                                                    <DropDownItem title="Desativar link público da etapa" icon={BsToggleOn} color={"#4681b8"} onClick={() => handleInactiveLinkPublicStep()} />
                                                                )}
                                                                <DropDownItem title="Configurar formulário público da etapa" icon={BsGearFill} color={"#4681b8"} onClick={() => setOpenFormPublicConfig(true)} />
                                                            </> :
                                                            <></>
                                                        }
                                                    </DropDownList>
                                                </DropDownShareMenuContainer>

                                                {openDisclaimerFormPublic && (
                                                    <DialogAction
                                                        open={openDisclaimerFormPublic}
                                                        message={"Você deseja ativar o link público desta etapa do cartão?"}
                                                        actions_items={[
                                                            "Ativação do compartilhamento do cartão;",
                                                            "Criação de um link para o compartilhamento público do cartão.",
                                                        ]}
                                                        disclaimer={"Lembrando que qualquer pessoa com este link poderá acessar as informações e etapa deste cartão."}
                                                        onSubmmit={handleCopyLinkPublicStep}
                                                        onClose={() => setOpenDisclaimerFormPublic(false)}
                                                        isLoading={loadingCopyLinkPublic}
                                                        type={4}
                                                    />
                                                )}

                                                {flow !== undefined && step !== undefined && step.form_id !== undefined && step.form_id !== null && openFormPublicConfig && (
                                                    <FormPublicConfig
                                                        open={openFormPublicConfig}
                                                        onClose={() => setOpenFormPublicConfig(false)}
                                                        flow={flow}
                                                        form_id={step.form_id}
                                                        card_id={card?.id_card}
                                                        isFromStep={true}
                                                    />
                                                )}

                                                <TimeTrackingContainer>
                                                    <TimeTracking
                                                        type="pocket"
                                                        card_id={card?.id_card}
                                                        flow_id={flow.id_flow}
                                                        flow_step_id={step?.id_step}
                                                        title={card?.title}
                                                    />
                                                </TimeTrackingContainer>

                                            </CurrentStepHeaderContainer>

                                            <StepContainerItem className="current-step-container" style={{ marginTop: '11px' }} color={step !== undefined && step.color !== undefined ? step.color : "#4d4d4d"}> {/* Formulário Inicial */}
                                                <StepContainerItemHeader>
                                                    <StepContainerItemHeaderLeft isCurrentStep={true} color={step !== undefined && step.color !== undefined ? step.color : "#4d4d4d"} >
                                                        {loadingPreAnswer ?
                                                            <ContainerLoader>
                                                                <Animation className="lottieContainer" ref={lottieContainer} />
                                                            </ContainerLoader> :
                                                            step !== undefined && step.icon !== undefined ?
                                                                <IconPickerItem
                                                                    icon={step.icon as IconList}
                                                                    color={step.color}
                                                                /> :
                                                                <IoMdListBox />
                                                        }
                                                    </StepContainerItemHeaderLeft>
                                                    <StepContainerItemHeaderDescription>
                                                        <StepContainerItemTitle
                                                            title={step !== undefined ? step.name : "..."}
                                                            required={false}
                                                            font_size="18px"
                                                            font_weight="600"
                                                        />
                                                        {step !== undefined && step.description !== "" ?
                                                            <StepContainerItemMadeBy>{step.description}</StepContainerItemMadeBy> :
                                                            <></>
                                                        }
                                                    </StepContainerItemHeaderDescription>
                                                </StepContainerItemHeader>
                                                {card !== undefined && step?.form.fields !== undefined && step?.form.fields.length > 0 ?
                                                    <ContainerFormCurrent>
                                                        <FormBuilder
                                                            id="formNewAnswer"
                                                            formRef={formRef}
                                                            fields={fields !== undefined ? fields : step?.form.fields}
                                                            handleSubmit={handleSubmit}
                                                            handleBlur={handleBlur}
                                                            hideContainer={true}
                                                            initialValue={preAnswer}
                                                            flow_id={card.flow_id}
                                                            card_current_id={card?.id_card}
                                                            card_current={card}
                                                            typeUser={typeUser}
                                                            activeHiddenFields={true}
                                                            updateFields={selectedValue !== undefined ? () => getApiPreAnswer(selectedValue.id_card, step) : undefined}
                                                        />
                                                    </ContainerFormCurrent> :
                                                    <></>
                                                }
                                            </StepContainerItem>
                                        </> : <></>}

                                    {getAccessControl(26, typeUser) ?
                                        <Link to={'/flow/3/' + flow.hash + "/edit/" + step?.id_step} style={{ textDecoration: 'none' }}>
                                            <EmptyContainer
                                                message="para customizar as etapas do fluxo"
                                                Icon={FaPlus}
                                            />
                                        </Link> : <></>
                                    }

                                    <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                                        <Title
                                            title="Colaboração"
                                            required={false}
                                            font_size="20px"
                                            font_weight="600"
                                        />
                                    </div>

                                    {loadingAttach ? (
                                        <LoaderAttach />
                                    ) : (
                                        <>
                                            {getAccessControl(29, typeUser) ?
                                                !!!uploadedFiles.length && (
                                                    <UploadButton onUploadFile={handleUploadFile} />
                                                ) : <></>
                                            }

                                            {!!uploadedFiles.length && (
                                                <Attachments typeUser={typeUser} uploadedFiles={uploadedFiles} onUploadFile={handleUploadFile} onDeleteFile={handleDeleteAttachment} />
                                            )}
                                        </>
                                    )}

                                    {loadingComments ? (
                                        <div style={{ marginTop: '15px' }}>
                                            <LoaderComment />
                                            <LoaderComment />
                                            <LoaderComment />
                                        </div>
                                    ) : (
                                        <History
                                            cardComments={comments}
                                            onDeleteComment={onDeleteComment}
                                            onFixComment={onFixComment}
                                            typeUser={typeUser}
                                        />)
                                    }

                                </ContainerCenterForm>
                                <ContainerCenterFooter isOpen={commentOpen}>
                                    {getAccessControl(44, typeUser) ?
                                        <CommentBox card_id={card?.id_card} flow_id={flow.id_flow} updateComments={updateComments} setOpenComments={setCommentOpen} /> :
                                        <></>
                                    }
                                    <FollowCardContainer>
                                        <FollowCardButtonLeft>
                                            <FollowCardDesc>
                                                Seguidores
                                            </FollowCardDesc>
                                            <AvatarListCange
                                                users={followers}
                                                flow_id={flow.id_flow}
                                                typeUser={typeUser}
                                                onAddUser={handleFollowCard}
                                                onRemoveUser={handleUnfollowCard}
                                                maxVisibleAvatars={4}
                                            />
                                        </FollowCardButtonLeft>

                                        <FollowCardButtonRight>
                                            {isFollower ?
                                                <FollowButton onClick={() => handleUnfollowCard()}>
                                                    <FaBellSlash />
                                                    Deixar de seguir
                                                </FollowButton> :
                                                <FollowButton onClick={() => handleFollowCard()}>
                                                    <FaBell />
                                                    Seguir este cartão
                                                </FollowButton>
                                            }
                                        </FollowCardButtonRight>
                                    </FollowCardContainer>
                                </ContainerCenterFooter>

                            </ContainerCenter>

                            {getAccessControl(25, typeUser) ?
                                <ContainerRight item xs={3} sm={3} md={2} lg={2} style={{ height: '100vh' }}>

                                    <ContainerRightTop style={{ marginBottom: '20px' }}>
                                        <div className="move-to-container" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                            <ContainerRightTitle>
                                                Mover para
                                            </ContainerRightTitle>

                                            {loadingSteps ? (
                                                <>
                                                    <LoaderStep />
                                                    <div style={{ marginBottom: '5px' }} />
                                                    <LoaderStep />
                                                    <div style={{ marginBottom: '5px' }} />
                                                    <LoaderStep />
                                                    <div style={{ marginBottom: '5px' }} />
                                                </>
                                            ) : (
                                                <>
                                                    {nextSteps?.map((step) => {

                                                        return (
                                                            <ContainerButtonAction
                                                                key={step.id_step}
                                                                classFastBtn={generateIdFromName(step.name) + "-button-fast"}
                                                                classActionBtn={showFlowDetail ? undefined : generateIdFromName(step.name) + "-button-action"}
                                                            >
                                                                <ButtonAction
                                                                    key={step.id_step}
                                                                    iconDynamic={step.icon !== undefined ? step.icon as IconList : "FaEllipsisH" as IconList}
                                                                    iconDynamicColor={"white"}
                                                                    color={step.color}
                                                                    title={step.name}
                                                                    onClick={() => handleMoveCard(step)}
                                                                    isLoading={loadingInsert}
                                                                    className={generateIdFromName(step.name) + "-button-action"}
                                                                >
                                                                    <span style={{ overflow: "hidden" }}>{step.name}</span>
                                                                </ButtonAction>
                                                                {!showFlowDetail && (
                                                                    <ButtonActionFast
                                                                        key={step.id_step + "-button-fast"}
                                                                        className={generateIdFromName(step.name) + "-button-fast"}
                                                                        color={step.color}
                                                                        title={step.name}
                                                                        isLoading={loadingInsert}
                                                                        tooltip="Mover e manter o cartão aberto"
                                                                        onClick={() => handleMoveCard(step, true)}
                                                                    >
                                                                        <TbArrowBarRight />
                                                                    </ButtonActionFast>
                                                                )}
                                                            </ContainerButtonAction>
                                                        );
                                                    })}

                                                    {previousSteps?.map((step) => {
                                                        return (
                                                            <ContainerButtonAction
                                                                key={step.id_step}
                                                                classFastBtn={generateIdFromName(step.name) + "-button-fast"}
                                                                classActionBtn={showFlowDetail ? undefined : generateIdFromName(step.name) + "-button-action"}
                                                            >
                                                                <ButtonActionBack
                                                                    key={step.id_step}
                                                                    iconDynamic={step.icon !== undefined ? step.icon as IconList : "FaEllipsisH" as IconList}
                                                                    iconDynamicColor={"white"}
                                                                    color={step.color + "1a"}
                                                                    title={step.name}
                                                                    onClick={() => handleMoveCard(step)}
                                                                    isLoading={loadingInsert}
                                                                    className={generateIdFromName(step.name) + "-button-action"}
                                                                >
                                                                    <span style={{ overflow: "hidden" }}>{step.name}</span>
                                                                </ButtonActionBack>
                                                                {!showFlowDetail && (
                                                                    <ButtonActionFast
                                                                        key={step.id_step + "-button-fast"}
                                                                        className={generateIdFromName(step.name) + "-button-fast"}
                                                                        color={step.color + "1a"}
                                                                        isLoading={loadingInsert}
                                                                        title={step.name}
                                                                        colorFont="#4d4d4d"
                                                                        onClick={() => handleMoveCard(step, true)}
                                                                        tooltip="Mover e manter o cartão aberto"
                                                                    >
                                                                        <TbArrowBarRight />
                                                                    </ButtonActionFast>
                                                                )}
                                                            </ContainerButtonAction>
                                                        );
                                                    })}

                                                    <Separator />
                                                </>
                                            )}
                                        </div>

                                        {getAccessControl(26, typeUser) ?
                                            <>
                                                <ButtonEditSteps title="Clique aqui para editar o relacionamento entre etapas" icon={TiFlowChildren} onClick={() => configCardOpen()}>Relacionamento da etapa</ButtonEditSteps>
                                                <ConfigSteps
                                                    open={openConfigStep}
                                                    onClose={configStepsClose}
                                                    flow_id={card?.flow_id}
                                                    flow_step_id={card?.flow_step_id}
                                                />
                                            </> : <></>
                                        }

                                        <Separator />

                                        <ContainerRightTitle>
                                            Adicionar ao cartão
                                        </ContainerRightTitle>

                                        <ButtonUserAssigned
                                            name="combo-user"
                                            typeUser={typeUser}
                                            user={selectedValue?.user}
                                            flow_id={selectedValue?.flow_id}
                                            id_card={selectedValue?.id_card}
                                            forceUpdate={updateObject}
                                        />

                                        <ButtonActionAddDueDate
                                            icon={FaRegCalendarAlt}
                                            color={"#9e37ed1a"}
                                            onClick={() => addDueDateOpen()}
                                            isFilled={card?.dt_due_string !== undefined ? true : false}
                                        >Data</ButtonActionAddDueDate>
                                        {card?.dt_due_string !== undefined ?
                                            <AddDueDateCompContainer>
                                                {card?.dt_due_string}
                                            </AddDueDateCompContainer> :
                                            <></>
                                        }
                                        <AddDueDate
                                            open={openAddDueDate}
                                            onClose={addDueDateClose}
                                            selectedValue={card}
                                        />

                                        <ButtonActionAddTag
                                            icon={FaTag}
                                            onClick={() => addLabelOpen()}
                                            isFilled={card?.card_flow_tags !== undefined && card?.card_flow_tags.length > 0}
                                        >
                                            Etiqueta
                                        </ButtonActionAddTag>
                                        {card?.card_flow_tags !== undefined && card?.card_flow_tags.length > 0 ?
                                            <AddTagContainer>
                                                {card.card_flow_tags.length} Etiquetas
                                            </AddTagContainer> :
                                            <></>
                                        }
                                        <AddLabel
                                            open={openAddLabel}
                                            onClose={addLabelClose}
                                            selectedValue={card}
                                        />

                                        {/*
                                <ButtonActionAdd icon={MdOutlineAttachFile} color={"#9e37ed1a"} onClick={() => console.log("Anexo")}>Anexo</ButtonActionAdd>
                                */}

                                    </ContainerRightTop>

                                    {getAccessControl(57, typeUser) ?
                                        <ContainerRightBottom>
                                            <ContainerRightTitle>
                                                Ações
                                                <AiOutlineInfoCircle title={"#" + card?.id_card} style={{ marginLeft: "5px" }} />
                                            </ContainerRightTitle>

                                            {card?.archived === undefined || card?.archived !== "S" ?
                                                <ButtonActionActionOff icon={BsFillArchiveFill} color="#4d4d4d1a" onClick={() => archiveCard("S")}>Arquivar</ButtonActionActionOff> :
                                                <>
                                                    <ButtonActionActionOff icon={BsFillArchiveFill} color="#4d4d4d1a" onClick={() => archiveCard("N")}>Desarquivar</ButtonActionActionOff>
                                                    <ButtonActionActionOff color_text="white" icon={FiTrash2} color="red" onClick={() => setOpenDelCard(true)}>Excluir</ButtonActionActionOff>
                                                </>
                                            }

                                            {openDelCard && (
                                                <DialogAction
                                                    open={openDelCard}
                                                    message={"Você tem certeza que deseja excluir este Cartão?"}
                                                    actions_items={[
                                                        "Exclusão de todos os dados do cartão;",
                                                        "Exclusão de todos os relacionamentos do cartão",
                                                    ]}
                                                    disclaimer={"Lembrando que após a confirmação não será mais possível recuperar os dados referentes ao Fluxo!"}
                                                    onSubmmit={dialogDelCardSubmmit}
                                                    onClose={() => setOpenDelCard(false)}
                                                    isLoading={loadingDelCard}
                                                    type={1}
                                                />
                                            )}
                                        </ContainerRightBottom> :
                                        <></>}

                                </ContainerRight> : <></>
                            }
                        </ContentBody>
                    </Content>
                </DialogContent>
            </DialogContainer > :

            <DialogContainer
                fullWidth={true}
                fullScreen={true}
                maxWidth="lg"
                open={open}
                onClose={handleClose}
                style={{ zIndex: 9999999 }}
            >
                <DialogContent style={{ padding: '0px' }}>
                    <DialogTop>

                        <TitleHeaderMobile>

                            <CardCloseBtnMobile className="btn-card-close" onClick={handleClose}>
                                Fechar
                            </CardCloseBtnMobile>

                            <CopyBtn onClick={handleCopyClick} style={{ position: 'absolute', right: '20px', top: '15px' }}>
                                {copiedLink ?
                                    <>
                                        <TbCheck />
                                        Copiado
                                    </> :
                                    <>
                                        <IoMdLink />
                                        Compartilhar
                                    </>
                                }
                            </CopyBtn>

                            <h1>
                                {card?.title}
                            </h1>

                            {card !== undefined && card.card_flow_tags !== undefined && card.card_flow_tags.length > 0 ?
                                <TagHeader>
                                    {card.card_flow_tags?.map((tag) => {
                                        return (
                                            <TaskTag key={tag.id_card_flow_tag} color={tag.flow_tag.color}>{tag.flow_tag.description}</TaskTag>
                                        );
                                    })}
                                </TagHeader> :
                                <></>
                            }

                            <DetailsContainerMobile>

                                <DetailsItemMobile color={step !== undefined && step.color !== undefined ? step.color : "red"}>
                                    {step !== undefined && step.icon !== undefined ?
                                        <IconPickerItem
                                            icon={step.icon as IconList}
                                            color={"white"}
                                        /> :
                                        <IoMdListBox />
                                    }
                                    {step !== undefined ? step.name : "..."}
                                </DetailsItemMobile>

                                {card?.archived !== undefined && card.archived === "S" ?
                                    <DetailsItemMobile color={step !== undefined && step.color !== undefined ? step.color : "red"}>
                                        <BsFillArchiveFill />
                                        {"Arquivado"}
                                    </DetailsItemMobile> :
                                    <></>
                                }

                                {card?.user !== undefined ?
                                    <DetailsItemMobile color={step !== undefined && step.color !== undefined ? step.color : "red"}>
                                        <FaUser />
                                        {card.user.name}
                                    </DetailsItemMobile> :
                                    <></>
                                }

                                {card?.dt_due_string !== undefined ?
                                    <DetailsItemMobile color={step !== undefined && step.color !== undefined ? step.color : "red"}>
                                        <FaCalendar />
                                        {card?.dt_due_string}
                                    </DetailsItemMobile> :
                                    <></>
                                }

                            </DetailsContainerMobile>
                        </TitleHeaderMobile>
                    </DialogTop>

                    <Content style={{ height: 'auto' }}>

                        <ContainerCenterMobile>

                            <SubHeaderRight>
                                <ButtonGroupLeft active={mobileTab === 0} onClick={() => setMobileTab(0)}>
                                    Histórico
                                </ButtonGroupLeft>
                                {getAccessControl(25, typeUser) ?
                                    <ButtonGroupCenter active={mobileTab === 1} onClick={() => setMobileTab(1)}>
                                        Etapa Atual
                                    </ButtonGroupCenter> :
                                    <></>
                                }
                                <ButtonGroupRight active={mobileTab === 2} onClick={() => setMobileTab(2)}>
                                    Colaboração
                                </ButtonGroupRight>
                            </SubHeaderRight>

                            {mobileTab === 0 ?
                                <div style={{ width: '100%' }}>

                                    {loadingOpen && (card === undefined || card.form_answers === undefined) && (
                                        <div style={{ marginTop: '10px' }}>
                                            <LoaderHistory />
                                            <LoaderHistory />
                                        </div>
                                    )}

                                    {card !== undefined && card.card_movement !== undefined ?
                                        <CardMovementLine
                                            card_movements={card.card_movement}
                                            flow={card?.flow}
                                            current_step={step}
                                        /> :
                                        <></>
                                    }

                                    {card !== undefined && card.form_answers !== undefined && card?.form_answers?.filter((answer) => answer.form_answer_fields.length > 0).map((form_answer) => {

                                        const stepCurrent = flow.flow_steps !== undefined ? flow.flow_steps.filter((step) => step.id_step === form_answer.flow_step?.id_step)[0] : undefined;

                                        return (
                                            <div id="tour-card-hist" key={form_answer.id_form_answer}>
                                                {form_answer.form !== undefined && form_answer.form.name === "FI" ?
                                                    <div>
                                                        <StepContainerItem className="form-init-container" key={form_answer.id_form_answer} color="#eef2f8"> {/* Formulário Inicial */}
                                                            <StepContainerItemHeader>
                                                                <StepContainerItemHeaderLeft color="#4d4d4d">
                                                                    <IoMdListBox />
                                                                </StepContainerItemHeaderLeft>
                                                                <StepContainerItemHeaderDescription>
                                                                    <StepContainerItemTitle
                                                                        title="Formulário Inicial"
                                                                        required={false}
                                                                        font_size="18px"
                                                                        font_weight="600"
                                                                    />
                                                                    {form_answer.origin === "/PublicForm" ?
                                                                        <StepContainerItemMadeBy>Criado via Formulário Público em {form_answer?.dt_created_string} <AiOutlineInfoCircle title={"#" + form_answer.id_form_answer} style={{ marginLeft: "5px" }} /></StepContainerItemMadeBy> :
                                                                        form_answer.origin === "/Automation/CreateNewCardService" || form_answer.origin === "/Automation/CreateChildCardService" ?
                                                                            <StepContainerItemMadeBy>Criado via Automações em {form_answer?.dt_created_string} <AiOutlineInfoCircle title={"#" + form_answer.id_form_answer} style={{ marginLeft: "5px" }} /></StepContainerItemMadeBy> :
                                                                            <StepContainerItemMadeBy>Criado por {form_answer.user_created} em {form_answer?.dt_created_string} <AiOutlineInfoCircle title={"#" + form_answer.id_form_answer} style={{ marginLeft: "5px" }} /></StepContainerItemMadeBy>
                                                                    }
                                                                </StepContainerItemHeaderDescription>
                                                            </StepContainerItemHeader>
                                                            <ContainerFormInit>
                                                                {flow.form_init !== undefined ?
                                                                    <FormAnswerRecord
                                                                        selectedValue={form_answer}
                                                                        form={flow.form_init}
                                                                        showDetailAnswer={false}
                                                                        hideContainer={true}
                                                                        notUpdateObject={true}
                                                                        forceUpdate={updateObject}
                                                                        typeUser={typeUser}
                                                                        flow_id={flow.id_flow}
                                                                        card_current_id={card?.id_card}
                                                                    /> :
                                                                    <></>}
                                                            </ContainerFormInit>
                                                        </StepContainerItem>
                                                        {getAccessControl(26, typeUser) ?
                                                            <Link to={'/flow/2/' + flow.hash + "/edit"} style={{ textDecoration: 'none' }}>
                                                                <EmptyContainer
                                                                    message="para customizar o formulário inicial"
                                                                    Icon={FaPlus}
                                                                />
                                                            </Link> : <></>
                                                        }
                                                    </div> : form_answer.form !== undefined && form_answer.flow_step !== undefined && form_answer.form !== null && form_answer.flow_step !== null ?
                                                        <StepContainerItem key={form_answer.id_form_answer} color="#eef2f8">
                                                            <StepContainerItemHeader>
                                                                <StepContainerItemHeaderLeft color={form_answer.flow_step !== undefined && form_answer.flow_step !== null && form_answer.flow_step.color !== undefined && form_answer.flow_step.color !== null ? form_answer.flow_step.color : "#4d4d4d"}>
                                                                    {form_answer.flow_step !== undefined && form_answer.flow_step !== null && form_answer.flow_step.icon !== undefined && form_answer.flow_step.icon !== null ?
                                                                        <IconPickerItem
                                                                            icon={form_answer.flow_step.icon as IconList}
                                                                            color={form_answer.flow_step !== undefined && form_answer.flow_step !== null && form_answer.flow_step.color !== undefined && form_answer.flow_step.color !== null ? form_answer.flow_step.color : "#4d4d4d"}
                                                                        /> :
                                                                        <IoMdListBox />
                                                                    }
                                                                </StepContainerItemHeaderLeft>
                                                                <StepContainerItemHeaderDescription>
                                                                    <StepContainerItemTitle
                                                                        title={form_answer.flow_step !== undefined && form_answer.flow_step !== null && form_answer.flow_step.name !== undefined && form_answer.flow_step.name !== null ? form_answer.flow_step.name : form_answer.form.name !== undefined && form_answer.form.name !== null ? form_answer.form.name : ""}
                                                                        required={false}
                                                                        font_size="18px"
                                                                        font_weight="600"
                                                                    />
                                                                    <StepContainerItemMadeBy>Movido por {form_answer.user_created} em {form_answer.dt_created_string} <AiOutlineInfoCircle title={"#" + form_answer.id_form_answer} style={{ marginLeft: "5px" }} /></StepContainerItemMadeBy>
                                                                </StepContainerItemHeaderDescription>

                                                            </StepContainerItemHeader>
                                                            <ContainerFormInit hide={form_answer.showDetail}>
                                                                {stepCurrent !== undefined ?
                                                                    <FormAnswerRecord
                                                                        selectedValue={form_answer}
                                                                        form={stepCurrent?.form}
                                                                        showDetailAnswer={false}
                                                                        hideContainer={true}
                                                                        forceUpdate={updateObject}
                                                                        notUpdateObject={true}
                                                                        typeUser={typeUser}
                                                                        flow_id={flow.id_flow}
                                                                        card_current_id={card?.id_card}
                                                                    /> :
                                                                    <></>
                                                                }
                                                            </ContainerFormInit>
                                                        </StepContainerItem> :
                                                        <></>
                                                }
                                            </div>
                                        );
                                    })}
                                </div> :
                                mobileTab === 1 ?
                                    <div style={{ width: '100%' }}>
                                        <StepContainerItem className="current-step-container" color={step !== undefined && step.color !== undefined ? step.color : "#4d4d4d"}>
                                            <StepContainerItemHeader>
                                                <StepContainerItemHeaderLeft color={step !== undefined && step.color !== undefined ? step.color : "#4d4d4d"} >
                                                    {loadingPreAnswer ?
                                                        <ContainerLoader>
                                                            <Animation className="lottieContainer" ref={lottieContainer} />
                                                        </ContainerLoader> :
                                                        step !== undefined && step.icon !== undefined ?
                                                            <IconPickerItem
                                                                icon={step.icon as IconList}
                                                                color={step.color}
                                                            /> :
                                                            <IoMdListBox />
                                                    }
                                                </StepContainerItemHeaderLeft>
                                                <StepContainerItemHeaderDescription>
                                                    <StepContainerItemTitle
                                                        title={step !== undefined ? step.name : "..."}
                                                        required={false}
                                                        font_size="18px"
                                                        font_weight="600"
                                                    />
                                                    {step !== undefined && step.description !== "" ?
                                                        <StepContainerItemMadeBy>{step.description}</StepContainerItemMadeBy> :
                                                        <></>
                                                    }
                                                </StepContainerItemHeaderDescription>
                                            </StepContainerItemHeader>
                                            {card !== undefined && step?.form.fields !== undefined && step?.form.fields.length > 0 ?
                                                <ContainerFormCurrent>
                                                    <FormBuilder
                                                        id="formNewAnswer"
                                                        formRef={formRef}
                                                        fields={fields !== undefined ? fields : step?.form.fields}
                                                        handleSubmit={handleSubmit}
                                                        handleBlur={handleBlur}
                                                        hideContainer={true}
                                                        initialValue={preAnswer}
                                                        flow_id={flow?.id_flow}
                                                        card_current_id={card?.id_card}
                                                        typeUser={typeUser}
                                                        setFields={setFields}
                                                        updateFields={selectedValue !== undefined ? () => getApiPreAnswer(selectedValue.id_card, step) : undefined}
                                                    />
                                                </ContainerFormCurrent> :
                                                <></>
                                            }
                                        </StepContainerItem>

                                        {getAccessControl(25, typeUser) ?
                                            <div>
                                                <MoveCardButtonMobile onClick={() => setButtonMoveMobile(!buttonMoveMobile)}>
                                                    {!buttonMoveMobile ? "Mover para" : "Fechar"}
                                                </MoveCardButtonMobile>

                                                {buttonMoveMobile ?
                                                    <>
                                                        {nextSteps?.map((step) => {
                                                            return (
                                                                <ButtonAction
                                                                    key={step.id_step}
                                                                    iconDynamic={step.icon !== undefined ? step.icon as IconList : "FaEllipsisH" as IconList}
                                                                    iconDynamicColor={"white"}
                                                                    color={step.color}
                                                                    onClick={() => handleMoveCard(step)}
                                                                    title={step.name}
                                                                    isLoading={loadingInsert}
                                                                >
                                                                    <span style={{ overflow: "hidden" }}>{step.name}</span>
                                                                </ButtonAction>
                                                            );
                                                        })}

                                                        {previousSteps?.map((step) => {
                                                            return (
                                                                <ButtonActionBack
                                                                    key={step.id_step}
                                                                    iconDynamic={step.icon !== undefined ? step.icon as IconList : "FaEllipsisH" as IconList}
                                                                    iconDynamicColor={"white"}
                                                                    color={step.color + "1a"}
                                                                    onClick={() => handleMoveCard(step)}
                                                                    title={step.name}
                                                                    isLoading={loadingInsert}
                                                                >
                                                                    <span style={{ overflow: "hidden" }}>{step.name}</span>
                                                                </ButtonActionBack>
                                                            );
                                                        })}
                                                    </> :
                                                    <></>
                                                }
                                            </div> :
                                            <></>
                                        }

                                    </div> :
                                    mobileTab === 2 ?
                                        <div style={{ width: '100%' }}>

                                            {getAccessControl(44, typeUser) ?
                                                <CommentBox card_id={card?.id_card} flow_id={flow.id_flow} updateComments={updateComments} setOpenComments={setCommentOpen} /> :
                                                <></>
                                            }

                                            {loadingAttach ? (
                                                <LoaderAttach />
                                            ) : (
                                                <>
                                                    {getAccessControl(29, typeUser) ?
                                                        !!!uploadedFiles.length && (
                                                            <UploadButton onUploadFile={handleUploadFile} />
                                                        ) : <></>
                                                    }

                                                    {!!uploadedFiles.length && (
                                                        <Attachments typeUser={typeUser} uploadedFiles={uploadedFiles} onUploadFile={handleUploadFile} onDeleteFile={handleDeleteAttachment} />
                                                    )}
                                                </>
                                            )}

                                            {loadingComments ? (
                                                <div style={{ marginTop: '15px' }}>
                                                    <LoaderComment />
                                                    <LoaderComment />
                                                    <LoaderComment />
                                                </div>
                                            ) : (
                                                <History
                                                    cardComments={comments}
                                                    onDeleteComment={onDeleteComment}
                                                    onFixComment={onFixComment}
                                                    typeUser={typeUser}
                                                />)
                                            }

                                        </div> :
                                        <></>
                            }

                        </ContainerCenterMobile>

                    </Content>
                </DialogContent>
            </DialogContainer >


    );
};

export default Card;

