import React from 'react';
import { Switch } from 'react-router-dom';

import ChangePassword from '../pages/ChangePassword';
import Dashboard from '../pages/Dashboard';
import EmailConfirmation from '../pages/EmailConfirmation';
import FirstStep from '../pages/NewFlow/FirstStep';
import Flow from '../pages/Flow'
import FlowList from '../pages/FlowList'
import NewRegisterFirstStep from '../pages/NewRegister/FirstStep';
import NewRegisterSecStep from '../pages/NewRegister/SecStep';
import Onboarding from '../pages/Onboarding/EmailData';
import OnboardingCompanyData from '../pages/Onboarding/CompanyData';
import OnboardingPersonalData from '../pages/Onboarding/PersonalData';
import OnboardingTemplateData from '../pages/Onboarding/TemplateData';
import OnboardingUseDataGroup from '../pages/Onboarding/UseDataGroup';
import OnboardingPurpouseData from '../pages/Onboarding/PurpouseData';
import RecoveryPassword from '../pages/RecoveryPassword';
import Register from '../pages/Register'
import RegisterInvitedUser from '../pages/RegisterInvitedUser';
import RegisterList from '../pages/RegisterList';
import Route from './Route';
import SecStep from '../pages/NewFlow/SecStep';
import SignIn from '../pages/SignIn';
import TaskList from '../pages/TaskList'
import TemplateStore from '../pages/TemplateStore'
import ThirStep from '../pages/NewFlow/ThirStep';
import UserConfigs from '../pages/UserConfigs'
import Workspace from '../pages/Workspace'
import TimeSheet from '../pages/TimeSheet'
import PublicFormPage from '../pages/PublicFormPage'
import CompanyConfigs from '../pages/CompanyConfigs';

const Routes: React.FC = () => (
    <Switch>
        {/* Open */}
        <Route path="/" exact component={SignIn} />
        <Route path="/login" exact component={SignIn} />
        <Route path="/login/:email+" exact component={SignIn} />

        <Route path="/public/:hash" exact component={PublicFormPage} isBothContext />
        <Route path="/public/:hash/:id" exact component={PublicFormPage} isBothContext />

        <Route path="/recovery" exact component={RecoveryPassword} />
        <Route path="/recovery-password/:hash" exact component={ChangePassword} />

        {/* <Route path="/new-here" exact component={NewHere}/>*/}
        <Route path="/register-invited/:hash" exact component={RegisterInvitedUser} />

        <Route path="/onboarding/" exact component={Onboarding} />
        <Route path="/onboarding/:url_from" exact component={Onboarding} />
        <Route path="/onboarding/:url_from/:hash" exact component={Onboarding} />
        <Route path="/onboarding-step-2/:hash" exact component={OnboardingPersonalData} />
        <Route path="/onboarding-step-3-1/:hash" exact component={OnboardingPurpouseData} />
        <Route path="/onboarding-step-3/:hash" exact component={OnboardingCompanyData} />
        <Route path="/onboarding-step-4/:hash" exact component={OnboardingUseDataGroup} />
        <Route path="/onboarding-step-5/:hash" exact component={OnboardingTemplateData} />

        {/* Both Context (isBothContext) */}
        <Route path="/mail-confirmation/:hash" exact component={EmailConfirmation} isBothContext />

        {/* Private (isPrivate) */}
        <Route path="/dashboard" exact component={Dashboard} isPrivate />

        <Route path="/template-store" exact component={TemplateStore} isPrivate exceptType={["S"]} />
        <Route path="/template-store/:id" exact component={TemplateStore} isPrivate exceptType={["S"]} />

        <Route path="/workspace/:hash" exact component={Workspace} isPrivate exceptType={["S"]} />

        <Route path="/flow/1/:hash/:origin" exact component={FirstStep} isPrivate exceptType={["S"]} />
        <Route path="/flow/2/:hash/:origin" exact component={SecStep} isPrivate exceptType={["S"]} />
        <Route path="/flow/3/:hash/:origin" exact component={ThirStep} isPrivate exceptType={["S"]} />
        <Route path="/flow/3/:hash/:origin/:id" exact component={ThirStep} isPrivate exceptType={["S"]} />

        <Route path="/register-list" exact component={RegisterList} isPrivate exceptType={["S"]} />
        <Route path="/register/1/:hash/:origin" exact component={NewRegisterFirstStep} isPrivate exceptType={["S"]} />
        <Route path="/register/2/:hash/:origin" exact component={NewRegisterSecStep} isPrivate exceptType={["S"]} />

        <Route path="/register/:hash" exact component={Register} isPrivate exceptType={["S"]} />
        <Route path="/register/:hash/:origin" exact component={Register} isPrivate exceptType={["S"]} />
        <Route path="/register/:hash/:origin/:id" exact component={Register} isPrivate exceptType={["S"]} />

        <Route path="/flow-list" exact component={FlowList} isPrivate />
        <Route path="/flow/:hash" exact component={Flow} isPrivate />
        <Route path="/flow/:hash/:origin" exact component={Flow} isPrivate />
        <Route path="/flow/:hash/:origin/:id" exact component={Flow} isPrivate />

        <Route path="/task-list" exact component={TaskList} isPrivate exceptType={["S"]} />
        <Route path="/task-list/:tab" exact component={TaskList} isPrivate exceptType={["S"]} />

        <Route path="/my-configs" exact component={UserConfigs} isPrivate />
        <Route path="/company-config" exact component={CompanyConfigs} isPrivate />
        <Route path="/insight-panel" exact component={TimeSheet} isPrivate exceptType={["S", "M", "V"]} />

    </Switch>
);

export default Routes;